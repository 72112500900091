import { useCallback, useMemo } from 'react';
import { CheckboxNodeWithParent, CheckboxTreeProps } from '../types';
import {
  buildItemsWithParents,
  getFlatSelectionPreview,
  getSubtreeSet,
  handleCheck,
} from '../utils';

export function useCheckboxTree<T>({
  items,
  selected,
  onSelectionChange,
  expanded,
  onExpandChange,
}: Pick<
  CheckboxTreeProps<T>,
  'items' | 'selected' | 'onExpandChange' | 'expanded' | 'onSelectionChange'
>) {
  const expandedSet = useMemo(() => new Set(expanded), [expanded]);

  const selectedSet = useMemo(() => new Set(selected), [selected]);

  const itemsWithParent = useMemo<CheckboxNodeWithParent<T>[]>(
    () => buildItemsWithParents(items),
    [items],
  );
  const handleCheckInner = useCallback(
    (checked: boolean, item: CheckboxNodeWithParent<T>) =>
      handleCheck(checked, item, selected, selectedSet, onSelectionChange),
    [selected, selectedSet, onSelectionChange],
  );

  const flatSelectionPreview = useMemo(
    () =>
      getFlatSelectionPreview(
        itemsWithParent,
        selectedSet,
        handleCheckInner,
        onExpandChange,
      ),
    [itemsWithParent, selectedSet, handleCheckInner, onExpandChange],
  );

  const clearAll = useCallback(() => {
    onSelectionChange([]);
  }, [onSelectionChange]);

  const selectAll = useCallback(() => {
    const selectedAll = items.map(i => Array.from(getSubtreeSet(i))).flat();
    onSelectionChange(selectedAll);
  }, [onSelectionChange, items]);

  const expandAll = useCallback(() => {
    const expandedAll = items.map(i => Array.from(getSubtreeSet(i))).flat();
    onExpandChange(expandedAll);
  }, [onExpandChange, items]);

  const collapseAll = useCallback(() => {
    onExpandChange([]);
  }, [onExpandChange]);

  return {
    expandedSet,
    selectedSet,
    flatSelectionPreview,
    itemsWithParent,
    clearAll,
    selectAll,
    collapseAll,
    expandAll,
  };
}
