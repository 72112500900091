import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useEnabledFeatures } from '../context';
import { useIsGuestUser } from './useOwnerProfileInfo';

export const useHomeUrl = () => {
  let homeURL = 'marketplace/search';
  const {
    loading: loadingFeatures,
    error,
    data: features,
  } = useEnabledFeatures();
  const { loading: loadingGuestInfo, data: isGuestUser } = useIsGuestUser();
  const config = useApi(configApiRef);
  const configUrl = config.getOptionalString('mesh.homeUrl');

  if (loadingFeatures || loadingGuestInfo) {
    return { loading: true, homeURL };
  }

  if (features.catalogEnabled) {
    homeURL = '/my-projects/catalog';
  }

  if (configUrl) {
    homeURL = configUrl;
  }

  if (isGuestUser) {
    homeURL = '/settings';
  }

  return { loading: false, error, homeURL, isGuestUser };
};
