import React from 'react';
import { GeneralEntityPickerDialog } from './GeneralEntityPickerDialog/GeneralEntityPickerDialog';
import { GeneralPurposePickerContextProvider } from './GeneralPurposePickerContext';
import { MultiTextField } from './MultipleTextField';
import { SingleTextField } from './SingleTextField';
import {
  GenericEntityType,
  Provider,
  TemplateEntity,
  TextFieldValue,
} from './types';
import { PreviewMessage } from '../CustomSnackbarError';
import { ProviderFilters } from './GeneralEntityPickerDialog/CommonFilter/types';

export interface EntityPickerProps {
  providerMapper: Map<string, Provider<GenericEntityType[], ProviderFilters>>;
  title: string;
  multiSelection?: boolean;
  availableKinds: TemplateEntity[];
  freeSolo: boolean;
  id?: string;
  onSelect: (val: any[] | undefined) => void;
  value: TextFieldValue[] | undefined;
  resolvedValues?: TextFieldValue[];
  parentTemplateRef?: string;
  templateError?: PreviewMessage;
  required: boolean;
}

export const WbGeneralPurposeEntityPicker = (props: EntityPickerProps) => {
  const {
    multiSelection,
    freeSolo,
    availableKinds,
    onSelect,
    resolvedValues,
    required,
  } = props;

  return (
    <GeneralPurposePickerContextProvider
      availableKinds={availableKinds}
      onSelect={onSelect}
      value={props.value}
      resolvedValues={resolvedValues}
      templateError={props.templateError}
    >
      {!multiSelection ? (
        <SingleTextField
          title={props.title}
          required={required}
          freeSolo={freeSolo}
          value={props.value?.[0]}
        />
      ) : (
        <MultiTextField
          title={props.title}
          freeSolo={freeSolo}
          values={props.value!}
          required={required}
        />
      )}
      <GeneralEntityPickerDialog {...props} />
    </GeneralPurposePickerContextProvider>
  );
};
