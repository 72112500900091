import { useQuery } from '@apollo/client';
import {
  DependencyGraph,
  DependencyGraphTypes,
  ErrorPanel,
  Progress,
} from '@backstage/core-components';
import { useTheme } from '@material-ui/core';
import React, { useContext } from 'react';

import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { GET_DATA_PRODUCT_INSTANCE_DEPENDENCIES_BY_ID_AND_ENV_ID } from '../../graphql';
import { useDependenciesStyle } from '../../themes';
import { parseDependenciesNodes } from '../../utils';
import { SystemContext } from '../../hooks/DataProductContext';
import { RenderedNode } from './RenderedNode';
import { SystemDependenciesEntities } from '@agilelab/plugin-wb-marketplace-common';

export const DependenciesCard = () => {
  const dpInstance = useContext(SystemContext);
  const theme = useTheme();
  const classes = useDependenciesStyle();
  const systemNodes = new Array<{
    id: string;
    kind: string;
    name: string;
    domain: string;
    dataProductInstanceId?: string;
  }>();
  const systemEdges = new Array<{
    from: string;
    to: string;
    label: string;
  }>();
  const { loading, error, data } = useQuery<SystemDependenciesEntities>(
    GET_DATA_PRODUCT_INSTANCE_DEPENDENCIES_BY_ID_AND_ENV_ID,
    {
      variables: {
        id: dpInstance.id,
        envId: dpInstance.environment.id,
      },
    },
  );

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return (
      <WbCard title="Dependencies">
        <WbCardContent>
          <ErrorPanel error={error} />
        </WbCardContent>
      </WbCard>
    );
  }
  const dependenciesNodes = parseDependenciesNodes(data);
  dependenciesNodes.forEach(dp => {
    systemNodes.push({
      id: dp.id.toString(),
      name: dp.displayName,
      kind: dp.type,
      domain: dp.domain,
      dataProductInstanceId: dp.id.dataProductInstanceId,
    });
    dp.inputIds?.forEach(link =>
      systemEdges.push({
        to: dp.id.toString(),
        from: link.toString(),
        label: '',
      }),
    );
  });

  return (
    <WbCard title="Dependencies">
      <WbCardContent>
        <DependencyGraph
          nodes={systemNodes}
          edges={systemEdges}
          nodeMargin={10}
          direction={DependencyGraphTypes.Direction.LEFT_RIGHT}
          renderNode={props => <RenderedNode {...props} />}
          paddingX={theme.spacing(4)}
          paddingY={theme.spacing(4)}
          className={classes.graph}
        />
      </WbCardContent>
    </WbCard>
  );
};
