import React, { useRef, useState } from 'react';
import { FilterListModal } from '../../FilterListModal';
import { FilterGroup } from '../FilterGroup';
import { CheckboxTree } from '../../CheckboxTree/CheckboxTree';
import { FilterInput } from '../../FilterInput';
import { useCheckboxTree } from '../../CheckboxTree/hooks/useCheckboxTree';
import { WbChip } from '../../../WbTextField';
import { CheckboxNode, FilterFn } from '../../CheckboxTree';
import { Typography } from '@material-ui/core';
import { WbCardActionButton } from '../../../WbCardActionButton';
import { BaseSidebarFilter, SidebarModalFilter } from '../types';

type CheckboxTreeFilterPropsGroup<T> = {
  countToShow?: number;
  searchPlaceholder?: string;
  placeholder?: string;
  values: CheckboxNode<T>[];
  selected: string[];
  onSelectionChange: (selected: string[]) => void;
  style?: React.CSSProperties;
  countToShowWithoutModal?: number;
  searchMatchFn?: FilterFn<T>;
} & BaseSidebarFilter &
  SidebarModalFilter;

export function CheckboxTreeFilterGroup<T>({
  countToShowWithoutModal = 8,
  label,
  values,
  selected,
  onSelectionChange,
  placeholder,
  searchPlaceholder,
  modalAnchorEl,
  modalStyle,
}: CheckboxTreeFilterPropsGroup<T>) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const defaultAnchorRef = useRef<HTMLDivElement>(null);

  const [modalSearch, setModalSearch] = useState('');

  const [modalselected, setModalselected] = React.useState<string[]>([]);

  const [modalExpanded, setModalExpanded] = React.useState<string[]>([]);

  // main tree
  const { flatSelectionPreview, clearAll } = useCheckboxTree({
    items: values,
    selected: selected,
    onSelectionChange: onSelectionChange,
    expanded: modalExpanded,
    onExpandChange: setModalExpanded,
  });

  // modal tree
  const {
    selectAll: modalSelectAll,
    clearAll: modalClearAll,
    expandAll,
    collapseAll,
  } = useCheckboxTree({
    items: values,
    selected: modalselected,
    onSelectionChange: setModalselected,
    expanded: modalExpanded,
    onExpandChange: setModalExpanded,
  });

  const open = Boolean(anchorEl);

  const handleShowMore = () => {
    setModalselected([...selected]);
    setAnchorEl(modalAnchorEl ?? defaultAnchorRef.current);
  };

  const showMoreEnabled = flatSelectionPreview.length > countToShowWithoutModal;

  return (
    <div ref={defaultAnchorRef}>
      <FilterGroup
        label={label}
        hideShowMore={!showMoreEnabled}
        onShowMore={handleShowMore}
      >
        <FilterInput
          onClick={handleShowMore}
          isOpened={open}
          onClear={() => undefined}
          selected={!!flatSelectionPreview.length}
          placeholder={placeholder ?? 'Select some values'}
          hideClear
        >
          <Typography
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {flatSelectionPreview.length} Selected
          </Typography>
        </FilterInput>
        {!!flatSelectionPreview.length && (
          <WbCardActionButton
            variant="text"
            label="Clear All"
            onClick={clearAll}
          />
        )}
        {(showMoreEnabled
          ? flatSelectionPreview.slice(0, countToShowWithoutModal)
          : flatSelectionPreview
        ).map(s => (
          <WbChip
            style={{
              maxWidth: 'fit-content',
              marginRight: 0,
            }}
            onClick={() => {
              s.expandTo();
              handleShowMore();
            }}
            key={s.id}
            label={s.label ?? s.id}
            onDelete={s.uncheck}
          />
        ))}
      </FilterGroup>
      <FilterListModal
        searchPlaceholder={searchPlaceholder}
        label={label}
        searchDebounced
        searchValue={modalSearch}
        onSearch={setModalSearch}
        onSelectAll={modalSelectAll}
        onClear={modalClearAll}
        onApply={() => {
          setAnchorEl(null);
          onSelectionChange(modalselected);
        }}
        extraActions={[
          { label: 'Expand All', action: expandAll },
          { label: 'Collapse All', action: collapseAll },
        ]}
        style={modalStyle}
        PopoverProps={{
          open,
          anchorEl,
          onClose: () => setAnchorEl(null),
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        <CheckboxTree
          items={values}
          filterValue={modalSearch}
          selected={modalselected}
          expanded={modalExpanded}
          onExpandChange={setModalExpanded}
          onSelectionChange={setModalselected}
        />
      </FilterListModal>
    </div>
  );
}
