import React, { useEffect, useMemo, useState } from 'react';
import { Deploy } from '../../types';
import { Box, IconButton, Tooltip, useTheme } from '@material-ui/core';
import {
  DagStatus,
  isRunningStatus,
  ProvisioningPlan,
  TaskAction,
} from '@agilelab/plugin-wb-builder-common';
import {
  CustomAlertApi,
  customAlertApiRef,
  poll,
  useDrawersContext,
  WbLink,
  WbScrollable,
} from '@agilelab/plugin-wb-platform';
import { useApi } from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '../../../../api';
import { useReleaseDetailPageContext } from '../../context/useReleaseDetailPageContext';
import { extractDagName } from '../../utils';
import { useStepIcons } from '../../hooks/useStepIcons';
import { ValidationGatewayStep } from './ValidationGatewayStep';
import { NavigateFunction, useNavigate } from 'react-router';

interface Props {
  deploy: Deploy;
}

export type MarketplaceProvisioningResult = {
  status: string;
  info?: {
    privateInfo?: {
      marketplaceInfo?: {
        type?: string;
        label?: string;
        href?: string;
        value?: string;
      };
    };
  };
};

const handleDeployFinishToast = (
  deploy: ProvisioningPlan & Deploy,
  alertApi: CustomAlertApi,
  navigate: NavigateFunction,
) => {
  const steps = deploy.dag.dependsOnTasks;
  const { action, status } = deploy.dag;
  if (status !== DagStatus.COMPLETED) return;
  let message = '';
  let link = undefined;
  if (action === 'PROVISION') {
    const dpInstanceData = steps.find(
      s => s.action === TaskAction.MARKETPLACE_UPDATE,
    );

    message = `Version ${deploy.version} successfully deployed`;
    if (dpInstanceData?.result) {
      const result = JSON.parse(dpInstanceData.result);
      const { info } = result as MarketplaceProvisioningResult;
      const href = info?.privateInfo?.marketplaceInfo?.href;
      if (href)
        link = (
          <WbLink
            route={href}
            externalLink={href.startsWith('http') ? href : undefined}
            text="Open in the marketplace"
            navigate={navigate}
          />
        );
    }
  } else if (action === 'UNPROVISION') {
    message = `Version ${deploy.version} successfully undeployed`;
  }

  if (message)
    alertApi.post({
      actions: link,
      message,
      severity: 'success',
      timeoutMillis: 5000,
    });
};

const DeployComponent: React.FC<Props> = ({ deploy }) => {
  const theme = useTheme();
  const { setSelectedStep, setSelectedDeploy, setIsValidationTestDrawerOpen } =
    useReleaseDetailPageContext();
  const stepIcons = useStepIcons();
  const { toggleBoolean } = useDrawersContext();

  return (
    <Box display="flex" alignItems="center">
      {deploy.steps.map((step, index) => (
        <React.Fragment key={step.id}>
          <Tooltip title={extractDagName(step)}>
            <IconButton
              style={{ padding: 0, margin: '0px -2px' }}
              size="small"
              disableRipple
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                if (
                  !isRunningStatus(step.status) &&
                  step.action === TaskAction.VALIDATION_GATEWAY
                ) {
                  setIsValidationTestDrawerOpen(true);
                  toggleBoolean(0);
                } else {
                  setSelectedStep(step);
                  setSelectedDeploy(deploy);
                }
              }}
            >
              {step.action === TaskAction.VALIDATION_GATEWAY ? (
                <ValidationGatewayStep step={step} />
              ) : (
                stepIcons[step.status]
              )}
            </IconButton>
          </Tooltip>
          {index !== deploy.steps.length - 1 && (
            <Box
              style={{
                width: '15px',
                height: '2px',
                background: theme.palette.grey[400],
              }}
            />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

const AsyncDeployComponent: React.FC<Props> = ({ deploy }) => {
  const {
    fetchDeploys,
    setIsDeployingAll,
    setIsUndeployingAll,
    setSelectedDeploy,
    setSelectedStep,
    fetchDeploymentUnitStatus,
    setTestTaskId,
    testTaskId,
    refreshMarketPlaceLink,
  } = useReleaseDetailPageContext();
  const panelCatalog = useApi(panelCatalogApiRef);
  const alertApi = useApi(customAlertApiRef);
  const [runningDeploy, setRunningDeploy] = useState<Deploy>(deploy);

  const navigate = useNavigate();

  useEffect(() => {
    const loadDeploy = () =>
      panelCatalog
        .getProvisioningPlan(deploy.id)
        .then(async provisioningPlan => {
          const result = {
            ...deploy,
            ...provisioningPlan,
            deployEndDate: provisioningPlan.dag.stopTime,
            status: provisioningPlan.dag.status,
            steps: provisioningPlan.dag.dependsOnTasks,
          };
          return result;
        });

    const controller = new AbortController();

    try {
      poll(
        loadDeploy,
        d => isRunningStatus(d.status),
        d => {
          // this check ensures to fetch all the validation tasks as soon as they are finished,
          // while the deployment process is still running
          if (
            d.steps[0].subPlanId &&
            d.steps[0].status !== DagStatus.RUNNING &&
            (!testTaskId || testTaskId !== d.steps[0].subPlanId)
          )
            setTestTaskId(d.steps[0].subPlanId);
          setSelectedDeploy(d);
          setSelectedStep(step => d.steps.find(s => s.id === step?.id));
          setRunningDeploy(d);
        },
        d => {
          fetchDeploys();
          refreshMarketPlaceLink();
          handleDeployFinishToast(d, alertApi, navigate);
          setIsDeployingAll(false);
          setIsUndeployingAll(false);
          fetchDeploymentUnitStatus();
        },
        { signal: controller.signal },
      );
    } catch (error) {
      alertApi.post({ error, severity: 'error' });
    }

    return () => {
      controller.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <DeployComponent deploy={runningDeploy} />;
};

export const DeployProcess: React.FC<Props> = ({ deploy }) => {
  const isRunning = useMemo(() => isRunningStatus(deploy.status), [deploy]);

  return (
    <WbScrollable>
      {isRunning ? (
        <AsyncDeployComponent deploy={deploy} />
      ) : (
        <DeployComponent deploy={deploy} />
      )}
    </WbScrollable>
  );
};
