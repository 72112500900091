import { useSelectorsContext, WbSearch } from '@agilelab/plugin-wb-platform';
import {
  WitboostSearchApi,
  witboostSearchApiRef,
} from '@agilelab/plugin-wb-search';
import {
  AtomicFilter,
  CollatorType,
  ComplexFilter,
  FilterOperator,
  INDEX_FIELDS_CONFIG_KEY,
  JsonObjectSearchFilterVisitor,
  QueryOperator,
  SEARCH_CONFIG_ROOT,
  WitboostMarketplaceSearchResultSet,
} from '@agilelab/plugin-wb-search-common';
import { configApiRef, useApi, useRouteRef } from '@backstage/core-plugin-api';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import lodash from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import useDebounce from 'react-use/lib/useDebounce';
import { marketplaceSearchRef } from '../../../routes';

interface SearchPageAutocompleteProps {
  onChange: (value: string) => void;
  query: string;
  handleSubmit: (event: any, value: string) => void;
}

const useStyles = makeStyles(
  theme => ({
    optionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    highlight: {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    searchBox: {
      width: '60%',
      boxShadow: theme.shadows[2],
      borderRadius: '4px',
    },
  }),
  { name: 'WbSearchAutocomplete' },
);

export function getDocumentFieldValues(
  document: any,
  condition: (el: string, key: string) => boolean,
  key: string = '',
): string[] {
  if (Array.isArray(document)) {
    return document.flatMap((value: any) => {
      return getDocumentFieldValues(value, condition, key);
    });
  } else if (typeof document === 'object') {
    return lodash.toPairs(document).flatMap(([k, value]: [string, any]) => {
      return getDocumentFieldValues(
        value,
        condition,
        key !== '' ? `${key}.${k}` : k,
      );
    });
  }
  const stringValue = document.toString();
  if (condition(stringValue, key)) {
    return [stringValue];
  }
  return [];
}

export const SearchPageAutocomplete = ({
  onChange,
  query,
  handleSubmit,
}: SearchPageAutocompleteProps) => {
  const [autocompleteValues, setAutoCompleteValues] =
    useState<WitboostMarketplaceSearchResultSet>();
  const [open, setOpen] = useState(false);
  const { environmentList } = useSelectorsContext();
  const searchApi: WitboostSearchApi = useApi(witboostSearchApiRef);
  const configApi = useApi(configApiRef);
  const classes = useStyles();

  const indexFields: string[] = (
    configApi.getOptionalConfigArray(
      `${SEARCH_CONFIG_ROOT}.${CollatorType.MARKETPLACE_PROJECTS}.${INDEX_FIELDS_CONFIG_KEY}`,
    ) ?? []
  ).map(indexField => indexField.getString('path'));

  useDebounce(
    () => {
      const complexFilter = new ComplexFilter(QueryOperator.AND, [
        new AtomicFilter(
          '_computedInfo.environment',
          FilterOperator.EQ,
          environmentList.find(e => e.priority === 0)?.name ?? 'prod',
        ),
      ]).accept(new JsonObjectSearchFilterVisitor());
      const getResults = async () => {
        const res = (await searchApi.query({
          term: query,
          filters: complexFilter,
          useHighlight: true,
          types: [CollatorType.MARKETPLACE_PROJECTS],
          pageLimit: 8,
        })) as WitboostMarketplaceSearchResultSet;
        setAutoCompleteValues(res);
      };
      if (!query || query === '') {
        setAutoCompleteValues(undefined);
        return;
      }
      getResults();
    },
    500,
    [query],
  );
  useEffect(() => {
    if (!query || query === '') {
      setOpen(false);
    }
  }, [query]);

  const [mappedAutocompleteValues, preTag, postTag]: [
    string[],
    string,
    string,
  ] = useMemo(() => {
    if (autocompleteValues) {
      const maxLength = 99;
      let startTag = '';
      let endTag = '';
      const resultSet = autocompleteValues?.results.flatMap(res => {
        startTag = res.highlight?.preTag ?? '';
        endTag = res.highlight?.postTag ?? '';
        if (!startTag || !endTag) return [];
        return getDocumentFieldValues(
          res.highlight?.fields ?? {},
          (el, key) =>
            indexFields.includes(key) &&
            el?.includes(startTag) &&
            el?.includes(endTag),
        ).map(valueWithTags => {
          // remove all the other tags after the first occurrence of the endTag
          const splitIndex = valueWithTags.indexOf(endTag) + endTag.length;
          const matchedValue =
            valueWithTags.slice(0, splitIndex) +
            valueWithTags
              .slice(splitIndex)
              .replaceAll(endTag, '')
              .replaceAll(startTag, '');

          // if the string is longer than the maxLength, we need to cut it
          if (
            matchedValue.length >
            maxLength + startTag.length + endTag.length
          ) {
            const preTagIndex = matchedValue.indexOf(startTag);
            const postTagIndex = matchedValue.indexOf(endTag);

            // if the string begins with the tag
            if (preTagIndex === 0) {
              const endIndex = maxLength + startTag.length + endTag.length;
              const endIndexWithNoSpaces =
                matchedValue.lastIndexOf(' ', endIndex) !== -1
                  ? matchedValue.lastIndexOf(' ', endIndex)
                  : endIndex;
              // remove all the characters after the last space before the maximum length
              return matchedValue.slice(0, endIndexWithNoSpaces);

              // if the string ends with the tag
            } else if (postTagIndex === matchedValue.length - endTag.length) {
              const startIndex =
                matchedValue.length -
                (maxLength + startTag.length + endTag.length);
              const startIndexWithNoSpaces =
                matchedValue.indexOf(' ', startIndex) !== -1
                  ? matchedValue.indexOf(' ', startIndex)
                  : startIndex;
              // remove all the characters before the first space of trailing the maximum length
              return matchedValue.slice(startIndexWithNoSpaces);
            }

            // otherwise it meas that the tag is in the middle of the string
            const remainingLength =
              maxLength +
              startTag.length +
              endTag.length -
              (postTagIndex + endTag.length - preTagIndex);
            // we need to find the closest spaces before and after the tag, with the tag in the middle
            const startIndex = preTagIndex - remainingLength / 2;
            const endIndex = postTagIndex + endTag.length + remainingLength / 2;
            const startIndexWithNoSpaces =
              matchedValue.indexOf(' ', startIndex) !== -1
                ? matchedValue.indexOf(' ', startIndex)
                : startIndex;
            const endIndexWithNoSpaces =
              matchedValue.lastIndexOf(' ', endIndex) !== -1
                ? matchedValue.lastIndexOf(' ', endIndex)
                : endIndex;
            // crop the string between the two spaces
            return matchedValue.slice(
              startIndexWithNoSpaces,
              endIndexWithNoSpaces,
            );
          }

          return matchedValue;
        });
      });
      return [[...new Set(resultSet)], startTag, endTag];
    }
    return [[], '', ''];
  }, [autocompleteValues, indexFields]);

  const getAutocompleteString = (option: string) => {
    if (!query || query === '') return <></>;
    const elements = option.split(preTag).flatMap((el, queryIndex) => {
      if (el.indexOf(postTag) === -1) return [el];
      const x = el.split(postTag);
      return [
        <span className={classes.highlight} key={queryIndex}>
          {x[0]}
        </span>,
        x[1],
      ];
    });
    return <>{elements}</>;
  };

  const navigate = useNavigate();
  const marketplaceSearch = useRouteRef(marketplaceSearchRef);

  const handleEmptySubmit = () => {
    navigate({
      pathname: marketplaceSearch(),
    });
  };

  return (
    <Autocomplete
      className={classes.searchBox}
      options={[...mappedAutocompleteValues]}
      freeSolo
      disableClearable
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      noOptionsText="No Suggested Description"
      value={query || ''}
      onChange={(_event, newValue, reason) => {
        onChange(newValue);
        if (reason === 'select-option') {
          handleSubmit(
            _event,
            newValue.replaceAll(preTag, '').replaceAll(postTag, ''),
          );
        }
      }}
      renderOption={option => (
        <Box className={classes.optionContainer}>
          <Typography>{getAutocompleteString(option)}</Typography>
        </Box>
      )}
      renderInput={params => (
        <WbSearch
          {...params}
          value={query}
          onChange={onChange}
          submit={handleEmptySubmit}
          placeholder="Type a specific keyword or click on the arrow to view the full list"
          showEnter
          style={{ borderRadius: '4px' }}
        />
      )}
    />
  );
};
