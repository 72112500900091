import { TablePagination } from '@material-ui/core';
import React from 'react';

export interface CountlessOptions {
  hasNextPage: () => boolean;
  hasPrevPage: () => boolean;
}

export interface WbPaginationProps {
  rowsPerPageOptions?: Array<number>;
  countlessOptions?: CountlessOptions;
  count: number;
  limit: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
  style?: React.CSSProperties;
  currentPage?: number;
  offset?: number;
}

export const WbPagination: React.FC<WbPaginationProps> = ({
  rowsPerPageOptions,
  count,
  limit,
  onPageChange,
  onRowsPerPageChange,
  countlessOptions,
  currentPage,
  offset,
  style,
}) => {
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const getPage = () => {
    if (currentPage) return currentPage;
    if (offset) return Math.floor(offset / limit);

    return 0;
  };

  return (
    !!count && (
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions || [25, 50, 75, 100, 200]}
        component="div"
        count={count}
        labelDisplayedRows={
          countlessOptions ? ({ from, to }) => `${from}-${to}` : undefined
        }
        nextIconButtonProps={
          countlessOptions
            ? {
                disabled: !countlessOptions.hasNextPage(),
              }
            : undefined
        }
        backIconButtonProps={
          countlessOptions
            ? {
                disabled: !countlessOptions.hasPrevPage(),
              }
            : undefined
        }
        rowsPerPage={limit}
        page={getPage()}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ alignSelf: 'flex-end', ...style }}
      />
    )
  );
};
