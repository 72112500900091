import { Config } from '@backstage/config';
import { InputError } from '@backstage/errors';
import {
  isValidSearchFilterType,
  SearchFilterConfigType,
  TextFilterMatch,
} from '../filters';
import { CollatorType } from './CollatorType';
import { MARKETPLACE_PROJECTS_DEFAULT_FILTERS } from './MarketplaceProject.defaults';
import { PROVISIONING_CONSUMABLE_DEFAULT_FILTERS } from './ProvisioningConsumable.defaults';

export const SEARCH_CONFIG_ROOT = 'mesh.search';
export const SEARCH_FILTERS_CONFIG_KEY = 'filters';
export const INDEX_FIELDS_CONFIG_KEY = 'indexFields';

const filtersDefaultConfig: CollatorFiltersConfig = {
  [CollatorType.MARKETPLACE_PROJECTS]: MARKETPLACE_PROJECTS_DEFAULT_FILTERS,
  [CollatorType.PROVISIONING_CONSUMABLES]:
    PROVISIONING_CONSUMABLE_DEFAULT_FILTERS,
};
export const getFiltersDefaultConfig = () => filtersDefaultConfig;

export const collatorDefaultConfig = {
  schedule: {
    frequency: { minutes: 10 },
    timeout: { minutes: 15 },
    initialDelay: { seconds: 3 },
  },
  collatorOptions: {
    batchSize: 100,
  },
};

export type CollatorFactoryConfigOptions = {
  batchSize: number;
};

export type IndexFactor = {
  path: string;
  relevance: string;
};

export type CollatorsIndexFields = {
  [collatorType in CollatorType]: IndexFactor[];
};

export type SearchGenericFilterConfig = {
  field: string;
  label: string;
  type:
    | SearchFilterConfigType.CHOICE
    | SearchFilterConfigType.BOOLEAN
    | SearchFilterConfigType.DATE
    | SearchFilterConfigType.CONSUMABLE
    | SearchFilterConfigType.DOMAIN
    | SearchFilterConfigType.TYPE
    | SearchFilterConfigType.FAVORITES;
};

export type SearchTextFilterConfig = {
  field: string;
  label: string;
  type: SearchFilterConfigType.TEXT;
  match?: TextFilterMatch;
};

export type SearchFilterConfig =
  | SearchGenericFilterConfig
  | SearchTextFilterConfig;

export type CollatorFiltersConfig = {
  [collatorType in CollatorType]: SearchFilterConfig[];
};

export function readIndexFieldsConfigOptions(
  configRoot: Config,
): CollatorsIndexFields {
  const indexFieldsConfig: CollatorsIndexFields = {
    [CollatorType.MARKETPLACE_PROJECTS]: [],
    [CollatorType.PROVISIONING_CONSUMABLES]: [],
  };

  const searchConfig = configRoot.getOptionalConfig(SEARCH_CONFIG_ROOT);
  if (!searchConfig) {
    return indexFieldsConfig;
  }

  searchConfig.keys().forEach(collatorType => {
    if (!Object.values(CollatorType).includes(collatorType as CollatorType)) {
      throw new InputError(
        `Invalid collator type in search configuration: ${collatorType}.
        Valid collator types are: ${Object.values(CollatorType).join(', ')}`,
      );
    }

    const indexFields =
      searchConfig.getOptionalConfigArray(
        `${collatorType}.${INDEX_FIELDS_CONFIG_KEY}`,
      ) ?? [];

    indexFieldsConfig[collatorType as CollatorType] = indexFields
      .map(configField => {
        const path = configField.getOptionalString('path');
        const relevance = configField.getOptionalString('relevance');
        if (path && relevance && ['A', 'B', 'C'].includes(relevance)) {
          return { path, relevance };
        }
        return undefined;
      })
      .filter(Boolean) as IndexFactor[];
  });

  return indexFieldsConfig;
}

export function readCollatorFiltersConfigOptions(
  configRoot: Config,
  includeDefaultFilters: boolean = true,
): CollatorFiltersConfig {
  const collatorInitialConfig: CollatorFiltersConfig = includeDefaultFilters
    ? { ...filtersDefaultConfig }
    : {
        [CollatorType.MARKETPLACE_PROJECTS]: [],
        [CollatorType.PROVISIONING_CONSUMABLES]: [],
      };
  const searchConfig = configRoot.getOptionalConfig(SEARCH_CONFIG_ROOT);
  if (!searchConfig) {
    return collatorInitialConfig;
  }

  const defaultFilterInfo: {
    // Only for duplicate check
    [key in CollatorType]?: { fields: Set<string>; labels: Set<string> };
  } = {};
  Object.keys(filtersDefaultConfig).forEach(collatorType => {
    const defaultFilters =
      filtersDefaultConfig[collatorType as CollatorType] || [];
    defaultFilterInfo[collatorType as CollatorType] = {
      fields: new Set(defaultFilters.map(f => f.field)),
      labels: new Set(defaultFilters.map(f => f.label)),
    };
  });

  searchConfig.keys().forEach(collatorType => {
    if (!Object.values(CollatorType).includes(collatorType as CollatorType)) {
      throw new InputError(
        `Invalid collator type in search configuration: ${collatorType}.
        Valid collator types are: ${Object.values(CollatorType).join(', ')}`,
      );
    }
    const filters =
      searchConfig.getOptionalConfigArray(
        `${collatorType}.${SEARCH_FILTERS_CONFIG_KEY}`,
      ) ?? [];
    const defaultInfo = defaultFilterInfo[collatorType as CollatorType];

    const seenFieldsAndLabels = {
      fields: new Set<string>(),
      labels: new Set<string>(),
    };

    const newFilters: SearchFilterConfig[] = filters.map(filterConfig => {
      const field = filterConfig.getString('field');
      const label = filterConfig.getString('label');
      const type = filterConfig
        .getString('type')
        .toLowerCase() as SearchFilterConfigType;
      if (!isValidSearchFilterType(type)) {
        throw new InputError(
          `Invalid search filter type in search filters configuration: ${type}.
          Valid search filter types are: ${Object.values(
            SearchFilterConfigType,
          ).join(', ')}`,
        );
      }

      if (defaultInfo?.fields.has(field) || defaultInfo?.labels.has(label)) {
        throw new InputError(
          `Found a conflict between a default filter and a custom filter in search filters configuration: ${field} ${label}.
          You cannot define the same field or label as a default filter and a custom filter. To solve this, go to the search filters configuration file and remove the custom filter.`,
        );
      }

      if (
        seenFieldsAndLabels.fields.has(field) ||
        seenFieldsAndLabels.labels.has(label)
      ) {
        throw new InputError(
          `Duplicate field or label in search filters configuration: ${field} ${label}.
          You cannot define the same field or label multiple times. To solve this, go to the search filters configuration file and remove the duplicate field or label.`,
        );
      }

      seenFieldsAndLabels.fields.add(field);
      seenFieldsAndLabels.labels.add(label);

      if (type === SearchFilterConfigType.TEXT) {
        const configMatch = filterConfig.getOptionalString('match') ?? 'begins';
        const match = configMatch.toLowerCase() as TextFilterMatch;
        if (match && !Object.values(TextFilterMatch).includes(match)) {
          throw new InputError(
            `Invalid text filter match in search filters configuration: ${match}.
            Valid text filter matches are: ${Object.values(
              TextFilterMatch,
            ).join(', ')}`,
          );
        }

        return { field, label, type, match };
      }

      return { field, label, type };
    });

    collatorInitialConfig[collatorType as CollatorType] = [
      ...collatorInitialConfig[collatorType as CollatorType],
      ...newFilters,
    ];
  });

  return collatorInitialConfig;
}
