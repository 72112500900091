import { ApolloClient } from '@apollo/client';
import { Domain, Domains } from '../components/ProductsGraphPage/utils';
import { GET_DOMAINS } from '../graphql';
import { DomainTree } from '../components/types';

/**
 * Load all the domains from the marketplace.
 * @param apolloClient the Apollo client to use to query the marketplace
 * @returns a map of all the domains, where the key is the domain id and the value is the domain entity
 */
export async function loadDomainsMap(
  apolloClient: ApolloClient<object>,
): Promise<Record<string, Domain>> {
  const marketplaceDomains = (
    await apolloClient.query<Domains>({
      query: GET_DOMAINS,
    })
  ).data.Domains;

  return marketplaceDomains.reduce<Record<string, Domain>>((acc, domain) => {
    acc[domain.id] = domain;
    return acc;
  }, {});
}

/**
 * Convert the domains in objects with a Tree structure that resembles their relationships
 * @param domains the flat domains
 * @returns {DomainTree[]} the domains in a Tree structure
 */
export function buildDomainTrees(domains: Domain[]): DomainTree[] {
  const domainsMap = domains.reduce<Record<string, Domain>>((acc, domain) => {
    acc[domain.id] = domain;
    return acc;
  }, {});

  // map the id of each domain node built to its node
  const builtNodes = new Map<string, DomainTree>();

  const res: DomainTree[] = [];

  domains.forEach(d => {
    // if the domain node for this id has not been built yet, build it
    if (!builtNodes.has(d.id))
      builtNodes.set(d.id, {
        id: d.id,
        label: d.name,
        children: [],
      });

    const node = builtNodes.get(d.id)!;

    const parentId = d.sub_domain_of?.[0]?.data.id;

    // add only the roots (no parent) to the results array
    if (!parentId) {
      res.push(node);
      return;
    }

    // if the parent of this domain node has not been built yet, build it
    if (!builtNodes.has(parentId)) {
      const parent = domainsMap[parentId];
      builtNodes.set(parentId, {
        id: parent.id,
        label: parent.name,
        children: [],
      });
    }

    const parentNode = builtNodes.get(parentId);

    // add the current domain node to the children array of the parent
    parentNode?.children.push(node);
  });

  return res;
}

/**
 * Load all the domains from the marketplace in a Tree structure
 * @param apolloClient the Apollo client to use to query the marketplace
 * @returns {DomainTree[]} the domains in a Tree structure
 */
export async function getDomainTrees(
  apolloClient: ApolloClient<object>,
): Promise<DomainTree[]> {
  const marketplaceDomains = (
    await apolloClient.query<Domains>({
      query: GET_DOMAINS,
    })
  ).data.Domains;

  return buildDomainTrees(marketplaceDomains);
}

/**
 * Given a domain, it resolves all the domain relations up to the root domain.
 * For every domain, it extracts the domain details using the extractor function (default is the domain name).
 * @param domainExternalId: the Domain external identifier to resolve
 * @param domainsMap: a map of all the domains (can be generated by invoking the method loadDomainsMap)
 * @param extractor: a function that extracts the domain details from the domain entity
 * @returns a list of strings representing the domain details for all the hierarchy of the domain. The list is ordered from the root domain to the input domain.
 */
export function resolveDomainRelations(
  domainExternalId: string,
  domainsMap: Record<string, Domain>,
  extractor: (domain: Domain) => string = domain => domain.name,
): string[] {
  const domain = domainsMap[domainExternalId];

  if (domain) {
    if (domain.sub_domain_of && domain.sub_domain_of.length === 1) {
      return [
        ...resolveDomainRelations(
          domain.sub_domain_of[0].data.id,
          domainsMap,
          extractor,
        ),
        extractor(domain),
      ];
    }
    return [extractor(domain)];
  }

  return [];
}
