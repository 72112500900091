import { useEffect, useLayoutEffect, useState } from 'react';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { FilterAvailableValuesByLabel, FilterState } from '../types';
import { validateFilterState } from '../filterValidators';
import { SearchFilterConfig } from '@agilelab/plugin-wb-search-common';
import { cleanFilters } from '../utils';

/**
 * Keeps the filter state valid against the available values of every filter;
 * Whenever the available values of the filters change, if the filter state is not compliant to them it is  updated removing the disallowed values
 * If the returned boolean is false it means the filter state is compliant to the current available values
 * @param filterState
 * @param setFilterState
 * @param availableValues
 * @param filterConfig
 * @returns Wheter the filter state is currently valid
 */
export const useFilterStateValidation = (
  filterState: FilterState | undefined,
  setFilterState: React.Dispatch<React.SetStateAction<FilterState | undefined>>,
  availableValues: AsyncState<FilterAvailableValuesByLabel>,
  filterConfig: SearchFilterConfig[],
) => {
  const [filterStateValid, setFilterStateValid] = useState(false);

  useLayoutEffect(() => {
    const values = availableValues.value;
    if (!values) return;
    setFilterState(currentState => {
      if (!currentState) return currentState;

      const { valid, cleanedValue } = validateFilterState(
        filterConfig,
        values,
        currentState,
      );

      if (!valid) {
        setFilterStateValid(false);
        setFilterState(cleanFilters(cleanedValue));
      }

      return currentState;
    });
  }, [availableValues.value, setFilterState, filterConfig]);

  useEffect(() => {
    const values = availableValues.value;
    if (filterStateValid || !values) return;
    if (
      !filterState ||
      validateFilterState(filterConfig, values, filterState).valid
    )
      setFilterStateValid(true);
  }, [filterState, filterStateValid, availableValues, filterConfig]);

  return filterStateValid;
};
