import { Content, Page } from '@backstage/core-components';

import {
  CustomViewDownloader,
  WbBreadcrumb,
  WbBreadcrumbs,
  WbEnvironmentSelector,
  WbHeader,
  WbHeaderActions,
} from '@agilelab/plugin-wb-platform';
import { makeStyles } from '@material-ui/core';
import React from 'react';

import { TaxonomySelector } from '@agilelab/plugin-wb-practice-shaper';
import { useRouteRef } from '@backstage/core-plugin-api';
import { searchHomepageRef } from '../../../routes';
import { SearchContextProvider } from '../context/SearchContext';
import { SearchResultsContent } from './SearchResultsContent';

const useStyles = makeStyles(
  () => ({
    content: {
      minHeight: 0,
    },
  }),
  { name: 'SearchResultsPage' },
);

export const SearchResultPage = () => {
  const classes = useStyles();
  const searchHomePage = useRouteRef(searchHomepageRef);
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <WbHeader
          title="Search"
          titleContainerStyle={{ flex: 'none' }}
          breadcrumbs={
            <WbBreadcrumbs aria-label="breadcrumb">
              <WbBreadcrumb>Data Marketplace</WbBreadcrumb>
              <WbBreadcrumb to={searchHomePage()}>Search</WbBreadcrumb>
              <WbBreadcrumb>Results</WbBreadcrumb>
            </WbBreadcrumbs>
          }
        >
          <CustomViewDownloader
            withMenu
            customViewIds={[
              'marketplace_search_system',
              'marketplace_search_component',
            ]}
          />
          <WbHeaderActions containerStyle={{ marginLeft: 'auto' }}>
            <TaxonomySelector />
            <WbEnvironmentSelector />
          </WbHeaderActions>
        </WbHeader>
        <Content className={classes.content}>
          <SearchResultsContent />
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
