import React from 'react';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  makeStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

type BaseCheckboxProps = Pick<FormControlLabelProps, 'label'> &
  Pick<CheckboxProps, 'checked' | 'onChange' | 'indeterminate'>;

const useStyles = makeStyles(
  () => ({
    root: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    icon: {
      fontSize: '20px',
    },
  }),
  { name: 'BaseCheckbox' },
);

export const BaseCheckbox = ({
  label,
  checked,
  indeterminate,
  onChange,
}: BaseCheckboxProps) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      classes={{
        label: classes.root,
      }}
      label={label}
      control={
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon className={classes.icon} />}
          checkedIcon={<CheckBoxIcon className={classes.icon} />}
          indeterminateIcon={
            <IndeterminateCheckBoxIcon
              className={classes.icon}
              color="primary"
            />
          }
          indeterminate={indeterminate}
          checked={checked}
          onChange={onChange}
        />
      }
    />
  );
};
