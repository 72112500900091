import { Box, ButtonProps } from '@material-ui/core';
import React from 'react';
import { WbCardActionButton } from '../WbCardActionButton';

interface Props {
  clear?: ButtonProps;
  selectAll?: ButtonProps;
  containerStyle?: React.CSSProperties;
  extraActions?: { label: string; action: () => void }[];
}

export const EnumButtons = ({
  clear,
  selectAll,
  containerStyle,
  extraActions,
}: Props) => {
  return (
    <Box
      style={{
        display: 'flex',
        gap: '16px',
        padding: '8px 16px 0px 16px',
        ...containerStyle,
      }}
    >
      {selectAll && (
        <WbCardActionButton variant="text" label="Select All" {...selectAll} />
      )}
      {clear && <WbCardActionButton variant="text" label="Clear" {...clear} />}
      {extraActions &&
        extraActions.map(a => (
          <WbCardActionButton
            variant="text"
            label={a.label}
            onClick={a.action}
          />
        ))}
    </Box>
  );
};
