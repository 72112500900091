import {
  AvailableSearchFilterValues,
  CollatorType,
  WitboostSearchQuery,
} from '@agilelab/plugin-wb-search-common';
import { DiscoveryApi } from '@agilelab/plugin-wb-user-config-client';
import { ApiRef, createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { SearchResultSet } from '@backstage/plugin-search-common';
import qs from 'qs';

export interface WitboostSearchApi {
  query(query: WitboostSearchQuery): Promise<SearchResultSet>;

  getAvailableFilterValues(
    collatorType: CollatorType,
  ): Promise<AvailableSearchFilterValues>;
}

export class WitboostSearchClient implements WitboostSearchApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async query(query: WitboostSearchQuery): Promise<SearchResultSet> {
    const { token } = await this.identityApi.getCredentials();
    const queryString = qs.stringify(query);
    const url = `${await this.discoveryApi.getBaseUrl(
      'search',
    )}/query?${queryString}`;
    const response = await fetch(url, {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  async getAvailableFilterValues(
    collatorType: CollatorType,
  ): Promise<AvailableSearchFilterValues> {
    const { token } = await this.identityApi.getCredentials();
    const url = `${await this.discoveryApi.getBaseUrl(
      'search',
    )}/filters/${collatorType}`;
    const response = await fetch(url, {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }
}

export const witboostSearchApiRef: ApiRef<WitboostSearchApi> =
  createApiRef<WitboostSearchApi>({
    id: 'wb-search',
  });
