import React, { createContext, ReactNode, useContext } from 'react';
import { FilterState } from './types';

type CheckboxTree = {
  selected: string[];
  expanded: string[];
  expandedSet: Set<string>;
  selectedSet: Set<string>;
  noNesting?: boolean;
  filterState?: FilterState;
  setFilterState: React.Dispatch<React.SetStateAction<FilterState | undefined>>;
  onExpandChange: (expandedIds: string[]) => void;
  onSelectionChange: (checkedIds: string[]) => void;
};

const defaultCheckboxTreeContext = null;

export const CheckboxTreeContext = createContext<CheckboxTree | null>(
  defaultCheckboxTreeContext,
);

export const useCheckboxTreeContext = () => {
  const contextValue = useContext(CheckboxTreeContext);

  if (!contextValue) {
    throw new Error(
      'useCheckboxTreeContext has to be used within <CheckboxTreeContext.Provider>',
    );
  }

  return contextValue;
};

export const CheckboxTreeContextProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: CheckboxTree;
}) => {
  return (
    <CheckboxTreeContext.Provider value={value}>
      {children}
    </CheckboxTreeContext.Provider>
  );
};
