import { gql } from '@apollo/client/core';

export const GET_SYSTEM_TOTAL = gql`
  query GetSystemTotal($where: marketplace_instances_bool_exp) {
    instance_aggregate: marketplace_instances_aggregate(where: $where) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

export const ALIGNMENT_CHART_DATA = gql`
  query AlignmentChartData($where: marketplace_instances_bool_exp) {
    instances: marketplace_instances(where: $where) {
      published_at
      inputPorts: relations(where: { name: { _eq: "readsFrom" } }) {
        data: instanceByTargetInstanceId {
          kind
        }
      }
    }
  }
`;

export const NETWORK_EFFECT = gql`
  query NetworkEffect($where: marketplace_instances_bool_exp) {
    instances: marketplace_instances(where: $where) {
      published_at
      inputPorts_aggregate: relations_aggregate(
        where: {
          _and: [
            { name: { _eq: "readsFrom" } }
            { instanceByTargetInstanceId: { kind: { _eq: "component" } } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_ALL_SYSTEMS_REVIEWS = gql`
  query GetAllSystemsReviews($where: marketplace_instances_bool_exp) {
    instances: marketplace_instances(where: $where) {
      reviews {
        score
      }
    }
    review_aggregate: marketplace_reviews_aggregate(
      where: { instance: $where }
    ) {
      aggregate {
        avg {
          score
        }
      }
    }
  }
`;

export const COUNT_SYSTEMS_GROUP_BY_ENV = gql`
  query CountSystemsGroupByEnv($where: marketplace_instances_bool_exp) {
    marketplace_environments(order_by: { priority: asc }) {
      instances_aggregate(where: $where) {
        aggregate {
          count
        }
      }
      name
    }
    marketplace_instances_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const SYSTEMS_USAGE = gql`
  query SystemsUsage(
    $where: marketplace_instances_bool_exp
    $inputPortWhere: marketplace_instances_bool_exp
  ) {
    instance_aggregate: marketplace_instances_aggregate(where: $where) {
      aggregate {
        count(distinct: true)
      }
    }
    inputPort_aggregate: marketplace_instances_aggregate(
      where: $inputPortWhere
    ) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

export const SYSTEMS_DISTRIBUTION = gql`
  query SystemDistribution($where: marketplace_instances_bool_exp) {
    systems: marketplace_instances(where: $where) {
      type
    }
  }
`;

export interface ValueByMonth {
  monthOfYear: string; // in the format MMM YYYY
  value: number;
}

export interface AlignmentByMonth {
  monthOfYear: string;
  totalSources: number;
  totalConsumers: number;
}
