import { Box, useTheme, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { AsyncEnumTableFilter } from './types';
import { FilterInput } from './FilterInput';
import { isEqual } from 'lodash';
import { BaseCheckbox } from './BaseFilters/BaseCheckbox';
import { FilterListModal } from './FilterListModal';

const AsyncEnumFilterValue = <T,>({
  field,
  value,
  renderValue,
}: AsyncEnumTableFilter<T>) => {
  const theme = useTheme();

  return (
    <Typography style={{ display: 'flex', alignItems: 'center' }}>
      {field}
      {value && value.length > 0 && (
        <>
          <Typography component="span">:</Typography>
          <Typography
            style={{ fontWeight: '600', marginLeft: '5px' }}
            component="span"
          >
            {value.length > 1 ? (
              <Box
                component="span"
                style={{ display: 'flex', gap: 5, alignItems: 'center' }}
              >
                {renderValue(value[0])}
                <Typography
                  variant="caption"
                  style={{
                    width: 18,
                    height: 18,
                    background: theme.palette.secondary.main,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '100%',
                    color: 'white',
                    fontSize: 9,
                    fontWeight: 600,
                  }}
                >
                  +{value.length - 1}
                </Typography>
              </Box>
            ) : (
              value.map(v => renderValue(v))
            )}
          </Typography>
        </>
      )}
    </Typography>
  );
};

export const AsyncEnumFilter = <T,>(filter: AsyncEnumTableFilter<T>) => {
  const [loading, setLoading] = useState(true);
  const [loadedOptions, setLoadedOptions] = React.useState<T[]>([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [options, setOptions] = React.useState<T[]>([]);
  const [value, setValue] = useState(filter.value || []);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  async function loadData() {
    try {
      setLoading(true);
      const data = await filter.getOptions();
      setLoadedOptions(data);
      setOptions(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    loadData();
  };
  const onSearch = (search: string) => {
    setSearchValue(search);
    if (filter.onSearch) setOptions(filter.onSearch(search, loadedOptions));
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setValue(filter.value || []);
      onSearch('');
    }, 500);
  };
  const onInputClear = () => {
    filter.onChange(undefined);
    setValue([]);
  };
  const onClear = () => {
    setValue([]);
  };
  const onSelectAll = () => {
    setValue(loadedOptions);
  };
  const onApply = () => {
    filter.onChange(value);
    setTimeout(() => {
      onSearch('');
    }, 500);
    setAnchorEl(null);
  };

  const onSelect = (checked: boolean, option: T) => {
    setValue(values =>
      checked ? values.concat(option) : values.filter(v => !isEqual(v, option)),
    );
  };

  React.useEffect(() => {
    setValue(filter.value || []);
  }, [filter.value]);

  return (
    <Box>
      <FilterInput
        onClick={openMenu}
        onClear={onInputClear}
        isOpened={open}
        selected={Boolean(filter.value?.length)}
        hideClear={filter.hideClear}
      >
        <AsyncEnumFilterValue {...filter} />
      </FilterInput>
      <FilterListModal
        loading={loading}
        searchValue={searchValue}
        onSearch={onSearch}
        onClear={onClear}
        onSelectAll={onSelectAll}
        onApply={onApply}
        style={{ maxHeight: '600px' }}
        PopoverProps={{
          open,
          anchorEl,
          onClose: closeMenu,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
      >
        {options.length <= 0 && (
          <Typography style={{ padding: '16px 0px' }}>No options</Typography>
        )}
        {options.map((option, index) => (
          <BaseCheckbox
            key={index}
            label={filter.renderOption(option)}
            checked={Boolean(value.find(v => isEqual(v, option)))}
            onChange={(_, checked) => onSelect(checked, option)}
          />
        ))}
      </FilterListModal>
    </Box>
  );
};
