/**
 * Extracts an array of IDs based on the provided conditions.
 *
 * @param isSystem - A boolean indicating whether the current entity is of system kind.
 * @param isShoppable - A boolean indicating whether the current entity is `SHOPPABLE`.
 * @param data - An object containing various data, expected to have an `outputPorts` property if `isSystem`.
 * @param entity - An object representing an entity, expected to have an `id` property.
 *
 * @returns An array of numbers:
 * - If `isSystem` and `isShoppable` are both true, returns the `id` values from `data.outputPorts`.
 * - If `isSystem` is false, returns an array with the `id` of the `entity`.
 * - Otherwise, returns an empty array.
 */
export const extractRightIds = (
  isSystem: boolean,
  isShoppable: boolean,
  entity: Record<string, any>,
): number[] => {
  if (isSystem && isShoppable)
    return entity?._components?.map((op: { id: number }) => op.id) as number[];
  if (!isSystem) return [entity?._computedInfo?.id];
  return [];
};
