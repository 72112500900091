import { Typography, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { FilterState, SearchFiltersContext } from '../../types';

import {
  BooleanFilterGroup,
  CheckboxFilterGroup,
  CheckboxNode,
  CheckboxTreeFilterGroup,
  CheckboxValue,
  DateFilterGroup,
  TextFilterGroup,
  WbCardActionButton,
  WbCardContent,
  WbDivider,
  getOnHoverScrollbarStyle,
} from '@agilelab/plugin-wb-platform';
import {
  SearchFilterConfig,
  SearchFilterConfigType,
  TextFilterMatch,
} from '@agilelab/plugin-wb-search-common';
import { ErrorPanel } from '@backstage/core-components';
import clsx from 'clsx';
import { cleanFilters, unsupportedSearchFilterConfigType } from '../../utils';
import { SearchFiltersLoader } from './SearchFiltersLoader';

export type SearchFiltersProps = {
  context: SearchFiltersContext;
  modalAnchorEl?: Element | null;
  modalHeight?: number;
  children?: ReactNode;
  hiddenFilterLabels?: string[];
  filters: FilterState | undefined;
  setFilters: React.Dispatch<React.SetStateAction<FilterState | undefined>>;
  favoriteFilter: boolean;
  setFavoriteFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles(
  theme => ({
    divider: {
      marginBlock: theme.spacing(1.5),
    },
    filtersSidebar: {
      ...getOnHoverScrollbarStyle(theme),
      scrollPaddingRight: '5px',
      flex: 1,
      overflowX: 'hidden',
      overflowY: 'scroll',
    },
    filtersHeader: {
      marginBlock: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    filtersHeaderText: {
      color: theme.palette.secondary.dark,
      fontWeight: 500,
    },
  }),
  { name: 'SearchFilters' },
);

export const SearchFilters = ({
  hiddenFilterLabels = [],
  context,
  filters = {},
  setFilters,
  setFavoriteFilter,
  favoriteFilter,
  modalAnchorEl,
  modalHeight,
  children,
}: SearchFiltersProps) => {
  const classes = useStyles();

  const renderFilter = (filter: SearchFilterConfig): ReactNode => {
    const valuesByLabel = context.availableValues.value ?? {};

    const { type } = filter;
    switch (type) {
      case SearchFilterConfigType.DOMAIN: {
        const values: CheckboxNode[] = valuesByLabel[filter.label] ?? [];
        if (!values.length) return null;
        return (
          <CheckboxTreeFilterGroup
            key={filter.label}
            modalAnchorEl={modalAnchorEl}
            label={filter.label}
            values={values}
            selected={filters[filter.label] ?? []}
            modalStyle={{ height: modalHeight }}
            searchPlaceholder={`Search ${filter.label}`}
            onSelectionChange={selection => {
              setFilters(old =>
                cleanFilters({
                  ...old,
                  [filter.label]: selection,
                }),
              );
            }}
          />
        );
      }
      case SearchFilterConfigType.TYPE:
      case SearchFilterConfigType.CHOICE: {
        const values: CheckboxValue[] = valuesByLabel[filter.label] ?? [];
        if (!values.length) return null;
        return (
          <CheckboxFilterGroup
            key={filter.label}
            modalAnchorEl={modalAnchorEl}
            label={filter.label}
            values={values}
            selected={filters[filter.label] ?? []}
            modalStyle={{ height: modalHeight }}
            searchPlaceholder={`Search ${filter.label}`}
            onSelectionChange={selection => {
              setFilters(old =>
                cleanFilters({
                  ...old,
                  [filter.label]: selection,
                }),
              );
            }}
          />
        );
      }
      case SearchFilterConfigType.TEXT: {
        const placeholders: Record<TextFilterMatch, string> = {
          [TextFilterMatch.EXACT]: `Type a ${filter.label} for an exact match`,
          [TextFilterMatch.CONTAINS]: `Type a ${filter.label} to match the ones that contain it`,
          [TextFilterMatch.BEGINS]: `Type a ${filter.label} to match the ones that begin with it`,
          [TextFilterMatch.ENDS]: `Type a ${filter.label} to match the ones that end with it`,
        };
        return (
          <TextFilterGroup
            label={filter.label}
            value={filters[filter.label] ?? ''}
            placeholder={placeholders[filter.match ?? TextFilterMatch.EXACT]}
            onChange={e => {
              setFilters(old =>
                cleanFilters({
                  ...old,
                  [filter.label]: e.target.value,
                }),
              );
            }}
          />
        );
      }
      case SearchFilterConfigType.FAVORITES:
        return (
          <BooleanFilterGroup
            label={filter.label}
            checked={favoriteFilter}
            onChange={() => setFavoriteFilter(!favoriteFilter)}
          />
        );
      case SearchFilterConfigType.CONSUMABLE:
      case SearchFilterConfigType.BOOLEAN:
        return (
          <BooleanFilterGroup
            label={filter.label}
            checked={filters[filter.label] === 'true'}
            onChange={e => {
              setFilters(old =>
                cleanFilters({
                  ...old,
                  [filter.label]: e.target.checked ? 'true' : 'false',
                }),
              );
            }}
          />
        );
      case SearchFilterConfigType.DATE:
        return (
          <DateFilterGroup
            label={filter.label}
            modalAnchorEl={modalAnchorEl}
            modalStyle={{ height: modalHeight }}
            onChange={range => {
              setFilters(old =>
                cleanFilters({
                  ...old,
                  [filter.label]: {
                    startDate: range?.startDate,
                    endDate: range?.endDate,
                  },
                }),
              );
            }}
            value={{
              startDate: filters[filter.label]?.startDate
                ? new Date(filters[filter.label].startDate)
                : undefined,
              endDate: filters[filter.label]?.endDate
                ? new Date(filters[filter.label].endDate)
                : undefined,
            }}
          />
        );

      default:
        return unsupportedSearchFilterConfigType(type);
    }
  };

  const hiddenSet = new Set(hiddenFilterLabels);
  const shownFilters = context.config.filter(c => !hiddenSet.has(c.label));

  if (context.availableValues.error)
    return <ErrorPanel error={context.availableValues.error} />;

  return (
    <>
      {context.availableValues?.loading ? (
        <SearchFiltersLoader context={context} />
      ) : (
        <>
          <WbCardContent className={classes.filtersHeader}>
            <Typography className={classes.filtersHeaderText}>
              Filters
            </Typography>
            <WbCardActionButton
              variant="text"
              label="Clear Filters"
              onClick={() => setFilters(undefined)}
            />
          </WbCardContent>
          <WbCardContent className={clsx(classes.filtersSidebar)}>
            {children}
            {children && (
              <WbDivider orientation="horizontal" className={classes.divider} />
            )}
            {shownFilters.map((f, i) => {
              const filter = renderFilter(f);
              if (!filter) return null;
              return (
                <React.Fragment key={f.label}>
                  {renderFilter(f)}
                  {i < shownFilters.length - 1 && (
                    <WbDivider
                      orientation="horizontal"
                      className={classes.divider}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </WbCardContent>
        </>
      )}
    </>
  );
};
