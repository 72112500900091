import { SearchFilterConfigType, TextFilterMatch } from '../filters';

export const MARKETPLACE_PROJECTS_URN = {
  field: '_computedInfo.urn',
  label: 'ID',
  type: SearchFilterConfigType.TEXT,
  match: TextFilterMatch.EXACT,
};

export const MARKETPLACE_PROJECTS_ENVIRONMENT = {
  field: '_computedInfo.environment',
  label: 'Environment',
  type: SearchFilterConfigType.CHOICE,
};

export const MARKETPLACE_PROJECTS_DATA_LANDSCAPE = {
  field: '_computedInfo.taxonomy.external_id',
  label: 'Data Landscape',
  type: SearchFilterConfigType.CHOICE,
};

export const MARKETPLACE_PROJECTS_TYPE = {
  field: 'kind',
  label: 'Type',
  type: SearchFilterConfigType.TYPE,
};

export const MARKETPLACE_PROJECTS_CONSUMABLE = {
  field: '_computedInfo.consumable',
  label: 'Consumable',
  type: SearchFilterConfigType.CONSUMABLE,
};
export const MARKETPLACE_FAVORITES = {
  field: 'id',
  label: 'Favorites',
  type: SearchFilterConfigType.FAVORITES,
};

export const MARKETPLACE_PROJECTS_DOMAIN = {
  field: '_computedInfo.domain.external_id',
  label: 'Domain',
  type: SearchFilterConfigType.DOMAIN,
};

export const MARKETPLACE_PROJECTS_DEFAULT_FILTERS = [
  MARKETPLACE_PROJECTS_URN,
  MARKETPLACE_PROJECTS_ENVIRONMENT,
  MARKETPLACE_PROJECTS_DATA_LANDSCAPE,
  MARKETPLACE_PROJECTS_TYPE,
  MARKETPLACE_PROJECTS_CONSUMABLE,
  MARKETPLACE_PROJECTS_DOMAIN,
  MARKETPLACE_FAVORITES,
];
