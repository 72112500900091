import { Descriptor } from '@agilelab/plugin-wb-marketplace-common';
import {
  CustomView,
  CustomViewDownloader,
  useDialog,
  WbDrawer,
} from '@agilelab/plugin-wb-platform';
import { Box } from '@material-ui/core';
import React from 'react';
import { SemanticLinkingTable } from '../OutputPortPage/OverviewPage/SemanticLinkingTable';

export interface SubcomponentDrawerProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  subcomponent?: Record<string, any>;
}

export const SubcomponentDrawer = (props: SubcomponentDrawerProps) => {
  const { open, setOpen, subcomponent } = props;
  const semanticLinkingDialog = useDialog({ title: 'Semantic Linking' });

  return (
    <WbDrawer
      titleContainerStyle={{ flex: 'none' }}
      open={open}
      setOpen={setOpen}
      title={subcomponent?.name ?? 'Subcomponent details'}
      actions={
        <Box style={{ marginRight: 'auto' }}>
          <CustomViewDownloader
            customViewIds={['marketplace_subcomponent_drawer']}
            withMenu
          />
        </Box>
      }
    >
      <CustomView
        id="marketplace_subcomponent_drawer"
        typeId={(subcomponent?.kind ?? '').toLowerCase()}
        templateId={subcomponent?.useCaseTemplateId ?? ''}
        data={subcomponent}
        actions={{
          showSemanticLink: async (field: any) => {
            await semanticLinkingDialog(
              <SemanticLinkingTable
                descriptor={subcomponent as Descriptor}
                schema={field}
              />,
            );
          },
        }}
      />
    </WbDrawer>
  );
};
