import { governanceApiRef } from '@agilelab/plugin-wb-governance';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  MetricResult,
  MetricThresholdColor,
  PolicyViolation,
  Timing,
} from '@agilelab/plugin-wb-governance-common';
import { InstanceEntity } from '@agilelab/plugin-wb-marketplace-common';
import { cgpEntityEdit, cgpEntityView } from '@agilelab/plugin-wb-rbac-common';
import {
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { usePermission } from '@backstage/plugin-permission-react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';
import React, { useContext, useMemo, useState } from 'react';
import { SystemContext } from '../../../hooks/DataProductContext';
import {
  GenericField,
  useDefaultThresholdColors,
  useDrawersContext,
  WbDrawer,
  WbTabProps,
  WbTabs,
} from '@agilelab/plugin-wb-platform';
import { PoliciesTable } from './PoliciesTable';
import { MetricsTable } from './MetricsTable';
import useAsync from 'react-use/lib/useAsync';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    border: {
      borderTop: `1px solid ${theme.palette.grey[400]}`,
    },
    infoIcon: {
      color: theme.palette.info.main,
      marginRight: theme.spacing(1),
    },
    warningIcon: {
      color: theme.palette.warning.main,
      marginRight: theme.spacing(1),
    },
    errorIcon: {
      color: theme.palette.error.main,
      marginRight: theme.spacing(1),
    },
    title: {
      color: theme.palette.primary.main,
    },
  }),
);
enum TabEnum {
  Policies,
  Metrics,
}

const WarningDetailsComponent = ({
  dpName,
  policyViolations,
  metricResults,
  allowLink,

  selectedTab,
  setSelectedTab,
}: {
  dpName: string;
  policyViolations: PolicyViolation[];
  metricResults: MetricResult[];
  allowLink: boolean;
  selectedTab: any;
  setSelectedTab: any;
}) => {
  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const tabs: WbTabProps[] = [
    {
      name: 'Policies',
    },
    {
      name: 'Metrics',
    },
  ];

  return (
    <>
      <Typography variant="h5">{dpName}</Typography>
      <Box>
        <WbTabs
          variant="default"
          tabs={tabs}
          handleChange={handleChange}
          selectedTab={selectedTab}
        />

        {selectedTab === TabEnum.Policies && (
          <PoliciesTable
            policyViolations={policyViolations}
            allowLink={allowLink}
          />
        )}
        {selectedTab === TabEnum.Metrics && (
          <MetricsTable metricResults={metricResults} allowLink={allowLink} />
        )}
      </Box>
    </>
  );
};

export function FlagAndScore(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const dpInstance = useContext<InstanceEntity>(SystemContext);
  const identityApi = useApi(identityApiRef);
  const governanceApi = useApi(governanceApiRef);

  const { toggleBoolean, drawers } = useDrawersContext();
  const [selectedTab, setSelectedTab] = useState<TabEnum>(TabEnum.Policies);

  const { defaultThresholdColors } = useDefaultThresholdColors();
  const configApi = useApi(configApiRef);
  const DEFAULT_COLOR = theme.palette.primary.main;
  const colors: MetricThresholdColor[] =
    configApi.getOptional('mesh.governance.metrics.thresholds.colors') ||
    defaultThresholdColors;

  const color = (labelColor: string) => {
    const relatedColor = colors.find(c => c.label === labelColor);

    return relatedColor?.value || DEFAULT_COLOR;
  };

  const { value: policyViolations } = useAsync(
    async () =>
      governanceApi
        .getPolicyViolations(
          {
            resource: dpInstance.external_id,
            environment: dpInstance.environment.name,
            timing: Timing.Runtime,
          },
          await identityApi.getCredentials(),
        )
        .then(r => r.violations),
    [identityApi],
  );

  const { value: metricResults } = useAsync(
    async () =>
      governanceApi
        .getMetricResults(
          {
            resource: dpInstance.external_id,
            environment: dpInstance.environment.name,
            timing: Timing.Runtime,
          },
          await identityApi.getCredentials(),
        )
        .then(r => r.results),
    [identityApi],
  );

  const policyViewPermission = usePermission({
    permission: cgpEntityView,
  }).allowed;
  const allowed =
    usePermission({
      permission: cgpEntityEdit,
    }).allowed || policyViewPermission;

  const violationsCountMap = useMemo(() => {
    if (!policyViolations) return undefined;

    return policyViolations.reduce(
      (countMap, { outcome }) => {
        switch (outcome) {
          case 'info':
            countMap.info++;
            break;
          case 'warning':
          case 'not_blocking_error':
            countMap.warning++;
            break;
          case 'error':
            countMap.error++;
            break;
          default:
            break;
        }
        return countMap;
      },
      {
        info: 0,
        warning: 0,
        error: 0,
      },
    );
  }, [policyViolations]);

  const metrics = useMemo(
    () =>
      metricResults?.reduce((acc, curr) => {
        const isError = !!curr.evaluationResult.errors.length;
        const key = isError
          ? 'Red'
          : curr.evaluationResult.thresholdResult?.labelColor ?? '';
        acc[key] = acc[key] || [];
        acc[key].push(curr);
        return acc;
      }, {} as { [key: string]: MetricResult[] }),
    [metricResults],
  );

  return policyViolations?.length || metricResults?.length ? (
    <>
      <Box
        style={{
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
          marginBottom: theme.spacing(2),
          marginTop: theme.spacing(2),
          width: '100%',
        }}
      />
      <Box display="flex" style={{ gap: 16 }}>
        {!!policyViolations?.length && (
          <GenericField
            label="Policies"
            value={
              <Box display="flex" style={{ gap: 8, minWidth: '250px' }}>
                {!!violationsCountMap?.warning && (
                  <Box
                    style={{ cursor: 'pointer' }}
                    display="flex"
                    alignItems="center"
                    onClick={() => {
                      toggleBoolean(0);
                      setSelectedTab(TabEnum.Policies);
                    }}
                  >
                    <FlagIcon className={classes.warningIcon} />
                    <Typography className={classes.title}>
                      {violationsCountMap.warning} Warning
                    </Typography>
                  </Box>
                )}
                {!!violationsCountMap?.info && (
                  <Box
                    style={{ cursor: 'pointer' }}
                    display="flex"
                    alignItems="center"
                    onClick={() => {
                      toggleBoolean(0);
                      setSelectedTab(TabEnum.Policies);
                    }}
                  >
                    <FlagIcon className={classes.infoIcon} />
                    <Typography className={classes.title}>
                      {violationsCountMap.info} Info
                    </Typography>
                  </Box>
                )}
                {!!violationsCountMap?.error && (
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      toggleBoolean(0);
                      setSelectedTab(TabEnum.Policies);
                    }}
                  >
                    <FlagIcon className={classes.errorIcon} />
                    <Typography className={classes.title}>
                      {violationsCountMap.error} Out of Policy
                    </Typography>
                  </Box>
                )}
              </Box>
            }
          />
        )}
        {!!metricResults?.length && (
          <GenericField
            label="Metrics"
            value={
              <Box display="flex" style={{ gap: 16, minWidth: '250px' }}>
                {metrics &&
                  Object.keys(metrics)?.map((key, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      style={{ cursor: 'pointer', gap: 4 }}
                      onClick={() => {
                        toggleBoolean(0);
                        setSelectedTab(TabEnum.Metrics);
                      }}
                    >
                      <FiberManualRecordIcon style={{ color: color(key) }} />
                      <Typography className={classes.title}>
                        {metrics[key]?.length}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            }
          />
        )}
      </Box>
      <WbDrawer
        open={drawers.get(0) ?? false}
        setOpen={() => toggleBoolean(0)}
        title="Test details"
      >
        <WarningDetailsComponent
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          dpName={dpInstance.display_name}
          policyViolations={policyViolations ?? []}
          metricResults={metricResults ?? []}
          allowLink={allowed}
        />
      </WbDrawer>
    </>
  ) : (
    <></>
  );
}
