import { MetricByIdAndEnvsQuery } from '@agilelab/plugin-wb-governance-common';
import { Tooltip, Typography } from '@material-ui/core';
import React, { ReactNode, useCallback } from 'react';
import {
  customAlertApiRef,
  GenericList,
  GenericListItem,
  ThresholdResult,
} from '@agilelab/plugin-wb-platform';
import {
  ComponentInstanceEntity,
  InstanceEntity,
} from '@agilelab/plugin-wb-marketplace-common';
import {
  GET_METRIC_BY_METRIC_ID_AND_ENVS,
  governanceApiRef,
} from '@agilelab/plugin-wb-governance';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import { useApolloClient } from '@apollo/client';
import useAsync from 'react-use/lib/useAsync';

const statusIconStyle = {
  marginRight: '8px',
  display: 'flex',
  alignItems: 'center',
};

export function MetricResultsOverview({
  title,
  hideIfEmpty = false,
  noDataMessage,
  metricIdFilter,
  system,
  components,
  wrapper,
}: {
  title?: string;
  hideIfEmpty?: boolean;
  noDataMessage?: string;
  metricIdFilter: string[];
  system: InstanceEntity;
  components: ComponentInstanceEntity[];
  wrapper?: (children: ReactNode) => JSX.Element;
}): JSX.Element {
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);
  const governanceApi = useApi(governanceApiRef);
  const apolloClient = useApolloClient();

  const fetchMetric = useCallback(
    async (metricId: string) => {
      const { data } = await apolloClient.query<MetricByIdAndEnvsQuery>({
        query: GET_METRIC_BY_METRIC_ID_AND_ENVS,
        variables: { metricId },
      });
      return data.cgp_governance_entity[0];
    },
    [apolloClient],
  );

  const resolveComponentName = (componentId: string) => {
    const component = components.find(c => c.external_id === componentId);
    if (!component) return undefined;
    return component.display_name ?? component.name;
  };

  const {
    loading,
    value: metricItems,
    error,
  } = useAsync(async () => {
    if (!metricIdFilter.length) return [];
    const { results } = await governanceApi.getMetricResults(
      {
        resource: system.external_id,
        environment: system.environment.name,
        metrics: metricIdFilter,
      },
      await identityApi.getCredentials(),
    );

    return await Promise.all(
      results.map(async result => {
        const metric = await fetchMetric(result.metricId);
        const variant =
          result.resourceVariantId &&
          (resolveComponentName(result.resourceVariantId) ??
            'internal component');
        return {
          metricName: metric.name,
          metricVersion: `v:${metric.version}`,
          metricDescription: metric.description,
          result: result.evaluationResult.thresholdResult,
          value: result.evaluationResult.value,
          error: !!result.evaluationResult.errors.length,
          variant: variant,
        };
      }),
    );
  }, [governanceApi, identityApi, alertApi, fetchMetric]);

  const items: GenericListItem[] | undefined = metricItems?.map(v => ({
    icon: (
      <ThresholdResult
        value={v.value ?? 0}
        result={v.result}
        hasError={v.error}
        variant="reduced"
        style={statusIconStyle}
      />
    ),
    text: (
      <Tooltip title={v.metricDescription ?? v.metricName}>
        <Typography>{`${v.metricName} ${v.metricVersion}`}</Typography>
      </Tooltip>
    ),
    additionalInfo: v.variant && `Computed on ${v.variant}`,
  }));

  if (hideIfEmpty && (loading || (items && !items.length))) return <></>;

  const list = (
    <GenericList
      label={title}
      isLoading={loading}
      isError={!!error}
      error={
        error && (
          <Typography>Metric results are temporarily unavailable</Typography>
        )
      }
      items={items}
      noData={<Typography>{noDataMessage ?? 'No results'}</Typography>}
    />
  );

  return wrapper ? wrapper(list) : list;
}
