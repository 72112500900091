import { DataContractPage } from './DataContractPage';
import { DataProductPage } from './DataProductPage';
import { OutputPortPage } from './OutputPortPage';
import { SubcomponentDrawer } from './SubcomponentsDrawer';

export * from './components';
export * from './DataContractCustomView';
export * from './DataContractView';
export * from './DataProductCustomView';
export * from './DataProductSubpages';
export * from './DataProductView';
export * from './OutportPortSubpages';
export * from './OutputPortCustomView';
export * from './OutputPortView';
export * from './schemaComponents';
export * from './SubcomponentsDrawerCustomView';
export const CustomViews = {
  DataContractPage,
  DataProductPage,
  OutputPortPage,
  SubcomponentDrawer,
};
