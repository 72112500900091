import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  theme => ({
    flex: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    borderRadius: {
      borderRadius: theme.spacing(2),
    },
  }),
  { name: 'BooleanFilterGroupLoader' },
);

export const BooleanFilterGroupLoader = () => {
  const classes = useStyles();
  return (
    <>
      <Skeleton variant="text" width={60} />
      <Box className={classes.flex}>
        <Skeleton variant="rect" className={classes.borderRadius} width={35} />
        <Skeleton variant="rect" width={60} />
      </Box>
    </>
  );
};
