import React from 'react';
import {
  WbCard,
  WbCardActionButton,
  WbCardContent,
} from '@agilelab/plugin-wb-platform';
import { OutputPortWithWarning, OutputPortWithWarnings } from '../types';
import { camelToSpacedCase } from '@agilelab/plugin-wb-platform-common';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router';
import { useRouteRef } from '@backstage/core-plugin-api';
import { specificResultRouteRef } from '@agilelab/plugin-wb-governance';

interface Props {
  outputPort: OutputPortWithWarnings;
}

const hasFalseValue = (obj: Record<string, boolean>) =>
  Object.entries(obj).some(([_, v]) => v === false);

const hasWarning = (obj: Record<string, Record<string, boolean>>) => {
  return Object.values(obj).some(hasFalseValue);
};

function getWarning(
  outputPort: OutputPortWithWarnings,
): OutputPortWithWarning | undefined {
  if (outputPort._portWarnings && outputPort._portWarnings.length > 0) {
    const warnings = outputPort._portWarnings;
    const warning = warnings.reduce(
      (acc, curr) => (curr.id > acc.id ? curr : acc),
      warnings[0],
    );
    if (
      warning &&
      !isEmpty(warning) &&
      warning?.content?.validation &&
      hasWarning(warning.content.validation)
    ) {
      return warning;
    }
  }
  return undefined;
}

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      display: 'flex',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    item: {
      display: 'flex',
      gap: 10,
      alignItems: 'center',
    },
    status: {
      width: 10,
      height: 10,
      borderRadius: '100%',
    },
    noData: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
  }),
);

const Status: React.FC<{ value: boolean }> = ({ value }) => {
  const classes = useStyles();
  const background = value ? '#8fd14f' : 'red';
  return <Box className={classes.status} style={{ background }} />;
};

export const DataContractResults: React.FC<Props> = ({ outputPort }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const specificResultRoute = useRouteRef(specificResultRouteRef);
  const classes = useStyles();

  const warning: OutputPortWithWarning | undefined = getWarning(outputPort);

  return (
    <WbCard
      title="Warnings"
      icon={<ErrorIcon color="primary" />}
      actions={
        warning && (
          <WbCardActionButton
            onClick={() => {
              navigate(
                specificResultRoute({ resultId: warning.content.resultId }),
              );
            }}
            label="Details"
            icon={<LaunchIcon />}
          />
        )
      }
      cardStyle={{ height: '100%' }}
    >
      <WbCardContent style={{ height: warning ? 'auto' : '100%' }}>
        <Grid container style={{ height: '100%' }}>
          {warning ? (
            <>
              {Object.keys(warning.content.validation).map(key => (
                <Grid item xs={6} key={key}>
                  <Typography variant="body1" color="primary">
                    {camelToSpacedCase(key)}
                  </Typography>
                  {Object.keys(warning.content.validation[key]).map(
                    contentKey => (
                      <Box key={contentKey} className={classes.item}>
                        <Status
                          value={warning.content.validation[key][contentKey]}
                        />
                        <Typography>{contentKey}</Typography>
                      </Box>
                    ),
                  )}
                </Grid>
              ))}
            </>
          ) : (
            <Box className={classes.noData}>
              <Typography
                component="p"
                style={{ color: theme.palette.grey[600] }}
              >
                Data Contract fully compliant
              </Typography>
            </Box>
          )}
        </Grid>
      </WbCardContent>
    </WbCard>
  );
};
