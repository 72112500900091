/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { EntityPicker } from '../components/fields/EntityPicker/EntityPicker';
import { EntityNamePicker } from '../components/fields/EntityNamePicker';
import { EntityTagsPicker } from '../components/fields/EntityTagsPicker/EntityTagsPicker';
import { OwnerPicker } from '../components/fields/OwnerPicker/OwnerPicker';
import { RepoUrlPicker } from '../components/fields/RepoUrlPicker/RepoUrlPicker';
import { OwnedEntityPicker } from '../components/fields/OwnedEntityPicker';
import {
  IdentifierPicker,
  identifierPickerValidation,
} from '../components/fields/IdentifierPicker';
import { EntitySelectionPicker } from '../components/fields/EntitySelectionPicker/EntitySelectionPicker';
import { repoPickerValidation } from '../components/fields/RepoUrlPicker/validation';
import { EntityRelationsPicker } from '../components/fields/EntityRelationsPicker/EntityRelationsPicker';
import { ReadsFromPicker } from '../components/fields/ReadsFromPicker/ReadsFromPicker';
import { ComponentIdentifierPicker } from '../components/fields/ComponentIdentifierPicker';
import { RbacRolePicker } from '../components/fields/RbacRolePicker';
import { RegexPicker } from '../components/fields/RegexPicker/RegexPicker';
import { CustomUrlPicker } from '../components/fields/CustomUrlPicker/CustomUrlPicker';
import { FieldValidation } from '@rjsf/core';
import { regExPickerValidation } from '../components/fields/RegexPicker/validation';
import { ApiHolder } from '@backstage/core-plugin-api';
import { customUrlPickerValidation } from '../components/fields/CustomUrlPicker';
import { isURL } from '@agilelab/plugin-wb-platform-common';
import { DescriptorPicker } from '../components/fields/DescriptorPicker';
import { FilePicker } from '../components/fields/FilePicker';
import { IdentitiesPicker } from '../components/fields/IdentitiesPicker';
import { AccessControlListPicker } from '../components/fields/AccessControlListPicker';
import { EntitySearchPicker } from '../components/fields/EntitySearchPicker/EntitySearchPicker';

export const DEFAULT_SCAFFOLDER_FIELD_EXTENSIONS = [
  {
    component: EntitySearchPicker,
    name: 'EntitySearchPicker',
  },
  {
    component: EntityPicker,
    name: 'EntityPicker',
  },
  {
    component: EntityNamePicker,
    name: 'EntityNamePicker',
  },
  {
    component: EntityTagsPicker,
    name: 'EntityTagsPicker',
  },
  {
    component: RepoUrlPicker,
    name: 'RepoUrlPicker',
    validation: repoPickerValidation,
    asyncValidation: false,
  },
  {
    component: OwnerPicker,
    name: 'OwnerPicker',
  },
  {
    component: OwnedEntityPicker,
    name: 'OwnedEntityPicker',
  },
  {
    component: IdentifierPicker,
    name: 'IdentifierPicker',
    validation: identifierPickerValidation,
    asyncValidation: true,
  },
  {
    component: ComponentIdentifierPicker,
    name: 'ComponentIdentifierPicker',
  },
  {
    component: EntitySelectionPicker,
    name: 'EntitySelectionPicker',
  },
  {
    component: EntityRelationsPicker,
    name: 'EntityComponentsPicker',
  },
  {
    component: EntityRelationsPicker,
    name: 'EntityRelationsPicker',
  },
  {
    component: ReadsFromPicker,
    name: 'ReadsFromPicker',
  },
  {
    component: RbacRolePicker,
    name: 'RbacRolePicker',
  },
  {
    component: RegexPicker,
    name: 'PathPicker',
    validation: (
      value: any,
      validation: FieldValidation,
      context: { apiHolder: ApiHolder },
    ) =>
      regExPickerValidation(value, validation, context, {
        validation: {
          regularExpression: `^(([/\\w-_]+)+/)?([\\w-_]+\\.\\w+)?$|^(([a-zA-Z]:|\\\\[\\w\\d-]+\\\\[\\w\\d-]+)\\\\|(.\\\\|\\\\)?)(((.)|(\\.\\.)|([\\w\\d-]+))\\\\)*([\\w\\d-]+)?$`,
          errorMessage: 'Must be a valid path',
          flags: '',
        },
      }),
    asyncValidation: false,
  },
  {
    component: RegexPicker,
    name: 'UrlPicker',
    validation: (
      value: any,
      validation: FieldValidation,
      _context: { apiHolder: ApiHolder },
    ) => {
      if (value && !isURL(value)) {
        validation.addError('Must be a valid URL (http or https only).');
        return;
      }
    },
    asyncValidation: false,
  },
  {
    component: RegexPicker,
    name: 'RegexPicker',
    validation: regExPickerValidation,
    asyncValidation: false,
  },
  {
    component: CustomUrlPicker,
    name: 'CustomUrlPicker',
    validation: customUrlPickerValidation,
    asyncValidation: true,
  },
  {
    component: DescriptorPicker,
    name: 'DescriptorPicker',
    asyncValidation: false,
  },
  {
    component: FilePicker,
    name: 'FilePicker',
  },
  {
    component: IdentitiesPicker,
    name: 'IdentitiesPicker',
    asyncValidation: false,
  },
  {
    component: AccessControlListPicker,
    name: 'AccessControlListPicker',
    asyncValidation: false,
  },
];
