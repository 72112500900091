import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { DataPreviewCard } from '../DataPreviewCard';
import { DependenciesCard } from '../DependenciesCard';
import { QuestionsCard } from '../QuestionsCard/QuestionsCard';
import { ReviewsCard } from '../Rating/ReviewsCard';
import { UserEntityContext } from '../../hooks/DataProductContext';
import { useAccessControlRequestTemplate } from '../OutputsCard/hooks/useAccessControlRequestTemplate';
import { AccessControlRequestDialog } from '../OutputsCard/AccessRequestDialog';
import { DataContractResults } from '../DataContractPage/DataContractResults';
import { DataQualityCard } from '../DataContractPage/DataQualityCard';

import {
  scaffolderApiRef,
  scaffolderTasksFinalizer,
} from '@agilelab/plugin-wb-scaffolder';
import { useApi } from '@backstage/core-plugin-api';
import {
  customAlertApiRef,
  CustomViewRegister,
  DrawersContextProvider,
  useAction,
  useDataPath,
  useUsername,
  WbCard,
  WbCardActionButton,
  WbCardContent,
} from '@agilelab/plugin-wb-platform';
import { AclVerb } from '@agilelab/plugin-wb-access-control-common';
import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useOutputPorts } from '../../hooks/useOutputPorts';
import { TechnicalInfoCard } from '../generic/TechnicalInfoCard';
import { FlagAndScore } from '../DataProductPage/FlagAndScore/FlagAndScore';
import { ShoppableMode } from '@agilelab/plugin-wb-marketplace-common';
import { extractRightIds } from './utils';
import { PolicyViolationsOverview } from '../DataProductPage/FlagAndScore/PolicyViolationsOverview';
import { MetricResultsOverview } from '../DataProductPage/FlagAndScore/MetricResultsOverview';

const GridItemWrapper = (children: ReactNode) => {
  const theme = useTheme();
  return (
    <Grid
      item
      style={{
        padding: `${theme.spacing(2)}px 0`,
        paddingRight: `${theme.spacing(4)}px`,
        paddingLeft: 0,
      }}
    >
      {children}
    </Grid>
  );
};

CustomViewRegister.register({
  id: 'marketplace_flag_and_score',
  function: function FlagAndScoreComponent(_: Record<string, any>) {
    return (
      <DrawersContextProvider>
        <FlagAndScore />
      </DrawersContextProvider>
    );
  },
});

CustomViewRegister.register({
  id: 'marketplace_policy_violations_overview',
  function: function PolicyViolationsOverviewComponent(
    props: Record<string, any>,
  ) {
    const data = useDataPath('');
    return (
      <PolicyViolationsOverview
        title={props.title}
        policyIdFilter={props.policyIds ?? []}
        hideIfEmpty={props.hideIfEmpty}
        noDataMessage={props.noDataMessage}
        system={data._computedInfo}
        components={data._components}
        wrapper={GridItemWrapper}
      />
    );
  },
});

CustomViewRegister.register({
  id: 'marketplace_metric_results_overview',
  function: function MetricResultsOverviewComponent(
    props: Record<string, any>,
  ) {
    const data = useDataPath('');
    return (
      <MetricResultsOverview
        title={props.title}
        metricIdFilter={props.metricIds ?? []}
        hideIfEmpty={props.hideIfEmpty}
        noDataMessage={props.noDataMessage}
        system={data._computedInfo}
        components={data._components}
        wrapper={GridItemWrapper}
      />
    );
  },
});

CustomViewRegister.register({
  id: 'data_preview',
  function: function PreviewCard(props: Record<string, any>) {
    const root = useDataPath('');
    return <DataPreviewCard descriptor={root} title={props.title} />;
  },
});

CustomViewRegister.register({
  id: 'marketplace_dependency',
  function: function DepCard() {
    return <DependenciesCard />;
  },
});

CustomViewRegister.register({
  id: 'marketplace_questions',
  function: function QuestCard() {
    return <QuestionsCard />;
  },
});

CustomViewRegister.register({
  id: 'marketplace_review',
  function: function RevCard() {
    return <ReviewsCard />;
  },
});

CustomViewRegister.register({
  id: 'marketplace_tech_card',
  function: function TechCard(props: Record<string, any>) {
    const root = useDataPath('');
    const title = props.title ?? 'Technical Information';
    const configs: string[] = props.configs;
    return (
      <TechnicalInfoCard
        title={title}
        configs={configs}
        descriptor={root ?? {}}
        wrapper={({ children }) => (
          <Grid item xs={12}>
            {children}
          </Grid>
        )}
      />
    );
  },
});

CustomViewRegister.register({
  id: 'marketplace_component_card',
  function: function OutCard(props: Record<string, any>) {
    const userEntity = useContext(UserEntityContext);
    const alertApi = useApi(customAlertApiRef);
    const scaffolderApi = useApi(scaffolderApiRef);
    const [scaffolderTaskIds, setScaffolderTaskIds] = useState<string[]>([]);
    const [isAccessRequestDialogOpen, setIsAccessRequestDialogOpen] =
      useState(false);
    const data = useDataPath('');
    const clearSelections = useAction('clearSelection');
    const clearSelection = () => clearSelections(data.kind);
    const grantTemplateResponse = useAccessControlRequestTemplate({
      templateType: 'grant',
    });
    const selectedOutputPorts = (data._parent._selectedComponents || []).filter(
      (s: any) =>
        (s.descriptor.kind || '').toLowerCase() ===
        (data.kind || '').toLowerCase(),
    );
    const ClearRequestsButton = () => (
      <Button variant="text" size="small" onClick={clearSelection}>
        Clear
      </Button>
    );
    const RequestAccessButton = () => (
      <Tooltip title={grantTemplateResponse.error ?? ''}>
        <span>
          <WbCardActionButton
            disabled={
              selectedOutputPorts.length === 0 ||
              !grantTemplateResponse.template
            }
            variant="contained"
            onClick={() => setIsAccessRequestDialogOpen(true)}
            size="small"
            color="primary"
            label="Request access"
          />
        </span>
      </Tooltip>
    );

    const CustomCardHeader = () => (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {selectedOutputPorts[0] ? (
          <RequestAccessButton />
        ) : (
          <Typography color="textPrimary">
            Select an interface to request access
          </Typography>
        )}
        {selectedOutputPorts[0] && <ClearRequestsButton />}
      </Box>
    );

    useEffect(() => {
      if (scaffolderTaskIds && scaffolderTaskIds.length > 0) {
        scaffolderTasksFinalizer(scaffolderTaskIds, scaffolderApi, alertApi);
      }
    }, [scaffolderTaskIds, scaffolderApi, alertApi]);

    return (
      <>
        {selectedOutputPorts[0] && (
          <AccessControlRequestDialog
            onRequestSent={() => clearSelection()}
            template={grantTemplateResponse.template}
            userEntity={userEntity}
            setScaffolderTaskIds={setScaffolderTaskIds}
            setIsOpen={setIsAccessRequestDialogOpen}
            isOpen={isAccessRequestDialogOpen}
            systemInstance={data._parent._computedInfo}
            selectedOutputPorts={selectedOutputPorts}
            verb={AclVerb.Grant}
          />
        )}
        <WbCard title={props.title} actions={<CustomCardHeader />}>
          <WbCardContent>{props.children}</WbCardContent>
        </WbCard>
      </>
    );
  },
});

CustomViewRegister.register({
  id: 'marketplace_info_card',
  function: function InfoCard(props: Record<string, any>) {
    const alertApi = useApi(customAlertApiRef);
    const entity = useDataPath('');
    const isSystem = entity._computedInfo?.kind === 'system';
    const isShoppable = entity._computedInfo?.shoppable;
    // neither `SHOPPABLE` nor `HAS_SHOPPABLE_CHILD`
    const isShoppableUndefined = !entity._computedInfo?.shoppable;
    const userEntity = useUsername();
    const [isAccessRequestDialogOpen, setIsAccessRequestDialogOpen] =
      useState(false);
    const [scaffolderTaskIds, setScaffolderTaskIds] = useState<string[]>([]);
    const scaffolderApi = useApi(scaffolderApiRef);
    const { selectedOutputPorts } = useOutputPorts(
      userEntity?.username,
      extractRightIds(isSystem, isShoppable, entity),
    );

    useEffect(() => {
      if (scaffolderTaskIds && scaffolderTaskIds.length > 0) {
        scaffolderTasksFinalizer(scaffolderTaskIds, scaffolderApi, alertApi);
      }
    }, [scaffolderTaskIds, scaffolderApi, alertApi]);

    const grantTemplateResponse = useAccessControlRequestTemplate({
      templateType: 'grant',
    });

    const componentNotShoppableError =
      !isSystem && isShoppableUndefined
        ? ('This component is not shoppable, and neither are its children. Please, make sure you are accessing to a shoppable component.' as string)
        : undefined;

    const systemNotShoppableError =
      isSystem && !isShoppable
        ? ('This system is not shoppable. Please, request access to one of its shoppable component instead.' as string)
        : undefined;

    const systemShoppableComponentShoppableError =
      !isSystem &&
      !isShoppableUndefined &&
      entity._system?.shoppable === ShoppableMode.Shoppable
        ? `This component or its children are shoppable, and so is its parent system. You have to request access from the General Information card of the system '${entity._system.name}'.`
        : undefined;

    const noConsumableChildrenError = !entity._computedInfo?.consumable
      ? `'${entity.name}' does not have any consumable children. Request Access is disabled.`
      : undefined;

    const systemTooltip =
      !systemNotShoppableError && isSystem
        ? ('Request Access to all of the consumable components and subcomponents belonging to this shoppable system.' as string)
        : undefined;

    const componentTooltip =
      !componentNotShoppableError &&
      !systemShoppableComponentShoppableError &&
      !isSystem
        ? ('Request Access to this shoppable component, if consumable, and all of its consumable subcomponents.' as string)
        : undefined;

    const RequestAccessButton = () => (
      <Tooltip
        title={
          grantTemplateResponse.error ??
          componentNotShoppableError ??
          systemNotShoppableError ??
          systemShoppableComponentShoppableError ??
          noConsumableChildrenError ??
          systemTooltip ??
          componentTooltip ??
          ''
        }
      >
        <span>
          <WbCardActionButton
            disabled={
              !grantTemplateResponse.template ||
              !!componentNotShoppableError ||
              !!systemNotShoppableError ||
              !!systemShoppableComponentShoppableError ||
              !!noConsumableChildrenError
            }
            variant="contained"
            onClick={() => setIsAccessRequestDialogOpen(true)}
            size="small"
            color="primary"
            label="Request access"
          />
        </span>
      </Tooltip>
    );
    const CustomCardHeader = () => (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <RequestAccessButton />
      </Box>
    );
    return (
      <>
        {selectedOutputPorts?.[0] && (
          <AccessControlRequestDialog
            onRequestSent={() => {}}
            template={grantTemplateResponse.template}
            userEntity={userEntity}
            setScaffolderTaskIds={setScaffolderTaskIds}
            setIsOpen={setIsAccessRequestDialogOpen}
            isOpen={isAccessRequestDialogOpen}
            systemInstance={isSystem ? entity._computedInfo : entity._system}
            selectedOutputPorts={selectedOutputPorts}
            verb={AclVerb.Grant}
          />
        )}
        <WbCard
          actions={<CustomCardHeader />}
          title={props.title}
          toolsStyle={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '8px 16px',
            wordBreak: 'break-word',
          }}
        >
          <WbCardContent>{props.children}</WbCardContent>
        </WbCard>
      </>
    );
  },
});
CustomViewRegister.register({
  id: 'marketplace_data_contract_result',
  function: function InfoCard(props: Record<string, any>) {
    const data = useDataPath(props.path || '');
    return <DataContractResults outputPort={data} />;
  },
});

CustomViewRegister.register({
  id: 'marketplace_data_contract_quality',
  function: function InfoCard(props: Record<string, any>) {
    const data = useDataPath(props.path || '');
    return <DataQualityCard outputPortEntity={data} />;
  },
});
