import React, { useCallback, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { BaseCheckbox } from '../../BaseFilters/BaseCheckbox';
import { WbSearch } from '../../../WbSearch';
import { WbCardActionButton } from '../../../WbCardActionButton';
import { FilterListModal } from '../../FilterListModal';
import { FilterGroup } from '../FilterGroup';
import { BaseSidebarFilter, SidebarModalFilter } from '../types';

export type CheckboxValue = { value: string; label?: string };

type CheckboxFilterPropsGroup = {
  countToShow?: number;
  searchPlaceholder?: string;
  values: CheckboxValue[];
  selected: string[];
  onSelectionChange: (selected: string[]) => void;
  style?: React.CSSProperties;
  countToShowWithoutModal?: number;
} & BaseSidebarFilter &
  SidebarModalFilter;

const defaultOnSearch = (value: string, values: CheckboxValue[]) =>
  values.filter(o => new RegExp(value, 'ig').test(o.label ?? o.value));

const useStyles = makeStyles(
  () => ({
    root: {},
    checkboxContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'CheckboxFilterGroup' },
);

export const CheckboxFilterGroup = ({
  countToShow = 5,
  countToShowWithoutModal = 10,
  label,
  values,
  selected,
  onSelectionChange,
  style,
  searchPlaceholder,
  modalAnchorEl,
  modalStyle,
}: CheckboxFilterPropsGroup) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const [showMore, setShowMore] = useState(false);

  const [searchValue, setSearchValue] = React.useState('');
  const [modalSearch, setModalSearch] = useState('');

  const [modalChecked, setModalChecked] = React.useState<string[]>([]);

  const checkedSet = useMemo(() => new Set(selected), [selected]);

  const modalCheckedSet = useMemo(() => new Set(modalChecked), [modalChecked]);

  const defaultAnchorRef = useRef<HTMLDivElement>(null);

  const open = Boolean(anchorEl);

  const handleShowMore: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (values.length > countToShowWithoutModal) {
      setModalChecked([...selected]);
      setAnchorEl(modalAnchorEl ?? defaultAnchorRef.current);
      return;
    }
    setShowMore(old => !old);
  };

  const modalFilteredOptions = useMemo(
    () => defaultOnSearch(modalSearch, values),
    [values, modalSearch],
  );

  const filteredOptions = useMemo(
    () =>
      defaultOnSearch(searchValue, values).sort((a, b) => {
        if (checkedSet.has(a.value) && !checkedSet.has(b.value)) return -1;
        if (checkedSet.has(b.value) && !checkedSet.has(a.value)) return 1;
        return 0;
      }),
    [values, searchValue, checkedSet],
  );

  const onSelectionChangeInner = useCallback(
    (name: string, isChecked: boolean) => {
      const filtered = selected.filter(i => i !== name);

      onSelectionChange(isChecked ? [...selected, name] : filtered);
    },
    [selected, onSelectionChange],
  );

  const onSelectionChangeModal = useCallback(
    (name: string, isChecked: boolean) => {
      const filtered = modalChecked.filter(i => i !== name);

      setModalChecked(isChecked ? [...modalChecked, name] : filtered);
    },
    [modalChecked],
  );

  return (
    <>
      <FilterGroup label={label}>
        <WbSearch
          value={searchValue}
          onChange={setSearchValue}
          placeholder={searchPlaceholder}
        />

        <WbCardActionButton
          variant="text"
          label="Clear All"
          onClick={() => onSelectionChange([])}
        />
        <div
          ref={defaultAnchorRef}
          className={classes.checkboxContainer}
          style={style}
        >
          {(filteredOptions.length > countToShow && !showMore
            ? filteredOptions.slice(0, countToShow)
            : filteredOptions
          ).map((option, index) => (
            <BaseCheckbox
              key={index}
              label={option.label ?? option.value}
              checked={checkedSet.has(option.value)}
              onChange={(_, isChecked) =>
                onSelectionChangeInner(option.value, isChecked)
              }
            />
          ))}
          {filteredOptions.length > countToShow && (
            <WbCardActionButton
              variant="text"
              label={showMore ? 'Show Less' : 'Show More'}
              onClick={handleShowMore}
            />
          )}
        </div>
      </FilterGroup>
      <FilterListModal
        searchPlaceholder={searchPlaceholder}
        label={label}
        searchValue={modalSearch}
        onSearch={setModalSearch}
        onSelectAll={() => {
          setModalChecked(values.map(v => v.value));
        }}
        onClear={() => {
          setModalChecked([]);
        }}
        onApply={() => {
          setAnchorEl(null);
          onSelectionChange(modalChecked);
        }}
        style={modalStyle}
        PopoverProps={{
          open,
          anchorEl,
          onClose: () => setAnchorEl(null),
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {modalFilteredOptions.map((option, index) => (
          <BaseCheckbox
            key={index}
            label={option.label ?? option.value}
            checked={modalCheckedSet.has(option.value)}
            onChange={(_, isChecked) =>
              onSelectionChangeModal(option.value, isChecked)
            }
          />
        ))}
      </FilterListModal>
    </>
  );
};
