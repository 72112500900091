import { ConsumableMode } from '@agilelab/plugin-wb-marketplace-common';
import {
  AtomicFilter,
  ComplexFilter,
  Filter,
  FilterOperator,
  QueryOperator,
  SearchFilterConfig,
  SearchFilterConfigType,
  TextFilterMatch,
} from '@agilelab/plugin-wb-search-common';
import qs from 'qs';
import { FilterState } from './types';

// guard function to make sure each possible SearchFilterConfigType is handled in a switch case statement
// must be called at default with the switch value so typescript will know at compile time and complain if not all cases are handled
export const unsupportedSearchFilterConfigType = (type: never): never => {
  throw new Error(`ERROR! The Filter type ${type} is not supported`);
};

// adapt from the way filters are stored in the local state of the page to the way the API needs them
export const adaptFilters = (
  filterState: Record<string, any>,
  filterConfig: SearchFilterConfig[],
  starredEntities?: string[],
): Filter[] => {
  const labelToConfigMap: Record<string, SearchFilterConfig> = {};
  filterConfig.forEach(t => {
    labelToConfigMap[t.label] = t;
  });

  const filters: Filter[] = [];

  filterConfig.forEach((filter: SearchFilterConfig) => {
    const value = filterState[filter.label];

    if (!value) return;

    const { type } = filter;

    switch (type) {
      case SearchFilterConfigType.DOMAIN:
      case SearchFilterConfigType.TYPE:
      case SearchFilterConfigType.CHOICE:
        filters.push(
          value.length === 1
            ? new AtomicFilter(filter.field, FilterOperator.EQ, value[0])
            : new AtomicFilter(filter.field, FilterOperator.IN, value),
        );
        break;

      case SearchFilterConfigType.TEXT: {
        const operator =
          filter.match === TextFilterMatch.EXACT
            ? FilterOperator.EQ
            : FilterOperator.LIKE;
        let likeValue = value;
        switch (filter.match) {
          case TextFilterMatch.EXACT:
            likeValue = value;
            break;
          case TextFilterMatch.CONTAINS:
            likeValue = `%${value}%`;
            break;
          case TextFilterMatch.ENDS:
            likeValue = `%${value}`;
            break;
          default:
            likeValue = `${value}%`;
        }
        filters.push(new AtomicFilter(filter.field, operator, likeValue));
        break;
      }

      case SearchFilterConfigType.CONSUMABLE:
        if (value === 'true')
          filters.push(
            new AtomicFilter(filter.field, FilterOperator.IN, [
              ConsumableMode.Consumable,
              ConsumableMode.HasConsumableChild,
            ]),
          );
        break;
      case SearchFilterConfigType.FAVORITES:
        if (value === 'true')
          filters.push(
            new AtomicFilter(
              'id',
              FilterOperator.IN,
              starredEntities
                ?.filter(el => el.startsWith('search:'))
                ?.map(el => el.replace('search:', '')) ?? [],
            ),
          );
        break;

      case SearchFilterConfigType.BOOLEAN:
        if (value === 'true')
          filters.push(
            new AtomicFilter(filter.field, FilterOperator.EQ, value),
          );
        break;

      case SearchFilterConfigType.DATE:
        if (value.startDate && value.endDate)
          filters.push(
            new ComplexFilter(QueryOperator.AND, [
              new AtomicFilter(
                filter.field,
                FilterOperator.GE,
                value.startDate,
              ),
              new AtomicFilter(filter.field, FilterOperator.LE, value.endDate),
            ]),
          );
        break;

      default:
        unsupportedSearchFilterConfigType(type);
    }
  });

  return filters;
};

// cleans the filters state object removing keys associated with empty arrays or objects (using the same settings as useQueryParamState)
export const cleanFilters = (filterState: FilterState) => {
  const cleaned = qs.parse(
    qs.stringify({ root: filterState }, { strictNullHandling: true }),
    {
      strictNullHandling: true,
      arrayLimit: 100,
      ignoreQueryPrefix: true,
    },
  );

  return cleaned.root as FilterState;
};
