import {
  ComponentInstanceEntity,
  InstanceEntity,
} from '@agilelab/plugin-wb-marketplace-common';
import { anyCaseToCapitalCase } from '@agilelab/plugin-wb-platform-common';
import _ from 'lodash';
import { CustomViewCtrls } from '@agilelab/plugin-wb-platform';

CustomViewCtrls.register({
  id: 'marketplace_component',
  function: ({
    componentInstance,
    dpInstance,
    consumableInterfaceTypeField,
    kindsLabels,
  }: {
    componentInstance: ComponentInstanceEntity;
    dpInstance: InstanceEntity;
    consumableInterfaceTypeField: string;
    kindsLabels: Record<string, string>;
  }): Record<string, any> => {
    const orderFn = (a: any, b: any) => {
      if (a.type < b.type) return -1;
      if (a.type > b.type) return 1;
      return 0;
    };
    const components: any[] = (componentInstance.descriptor.components ?? [])
      ?.map((component: any) => ({
        ...component,
        type:
          typeof component[consumableInterfaceTypeField] === 'string'
            ? anyCaseToCapitalCase(component[consumableInterfaceTypeField])
            : undefined,
      }))
      .sort(orderFn);
    const kinds = components
      ?.map((component: any) => component.kind.toLowerCase() || '')
      .filter((val, idx, list) => list.indexOf(val) === idx);
    const componentsByKind = kinds?.map(kind =>
      Object.assign(
        { kind, label: (kindsLabels || {})[kind] || kind },
        {
          components: components
            ?.filter(component => component.kind.toLowerCase() === kind)
            .sort(orderFn),
        },
      ),
    );
    return {
      ...componentInstance.descriptor,
      _system: dpInstance,
      _computedInfo: componentInstance,
      _componentsByKind: componentsByKind,
    };
  },
});
