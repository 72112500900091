import React from 'react';
import { DomainHandlerProvider } from './DomainProvider/DomainProvider';
import { SystemHandlerProvider } from './SystemProvider/SystemProvider';
import {
  GenericEntityType,
  GenericProviderType,
  Provider,
  ProviderFilters,
  TemplateEntity,
} from '@agilelab/plugin-wb-platform';
import { ResourceHandlerProvider } from './ResourceProvider/ResourceProvider';
import { WitboostSearchApi } from '@agilelab/plugin-wb-search';
import { CatalogApi } from '@backstage/catalog-client';
import { LazyQueryResult, OperationVariables } from '@apollo/client';
import { ConsumablesHandlerProvider } from './ConsumableProvider/ConsumablesProvider';

export enum AvailableEntities {
  SYSTEM = 'System',
  DOMAIN = 'Domain',
  RESOURCE = 'Resource',
  CONSUMABLE = 'Consumable',
}

export const entityHandler = (
  searchApi: WitboostSearchApi,
  catalogApi: CatalogApi,
  availableKinds: TemplateEntity[],
  fetchEnvironments: () => Promise<
    LazyQueryResult<
      {
        marketplace_environments: {
          name: string;
          priority: number;
        }[];
      },
      OperationVariables
    >
  >,
) =>
  new Map<string, Provider<GenericEntityType[], ProviderFilters>>([
    [
      AvailableEntities.SYSTEM,
      new SystemHandlerProvider(catalogApi, availableKinds),
    ],
    [
      AvailableEntities.DOMAIN,
      new DomainHandlerProvider(catalogApi, availableKinds),
    ],
    [
      AvailableEntities.RESOURCE,
      new ResourceHandlerProvider(catalogApi, availableKinds),
    ],
    [
      AvailableEntities.CONSUMABLE,
      new ConsumablesHandlerProvider(
        searchApi,
        availableKinds,
        fetchEnvironments,
      ),
    ],
  ]);

export function contextProviderFactory<
  T extends GenericProviderType<GenericEntityType[], ProviderFilters>,
>(): [() => T, React.Provider<T | undefined>] {
  const context = React.createContext<T | undefined>(undefined);

  const useProvider = () => {
    const c = React.useContext(context);

    if (c === undefined) {
      throw new Error(
        'The hook "useProvider" must be used within an existing context.',
      );
    }

    return c;
  };

  return [useProvider, context.Provider];
}
