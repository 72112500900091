import {
  CountlessOptions,
  decodeBase64PageCursor,
  encodeBase64PageCursor,
  useElementSize,
  useSelectorsContext,
  WbCard,
  WbCardActionButton,
  WbDivider,
  WbDockableGroup,
  WbDockablePanel,
  WbDockableResizeHandle,
  WbNoData,
  WbPagination,
  WbSearch,
} from '@agilelab/plugin-wb-platform';
import { Box, List, makeStyles, Typography } from '@material-ui/core';
import React, { ReactNode, useMemo, useRef } from 'react';

import { camelCaseToCapitalCase } from '@agilelab/plugin-wb-platform-common';
import { SearchFilters } from '@agilelab/plugin-wb-search';
import {
  MARKETPLACE_PROJECTS_CONSUMABLE,
  MARKETPLACE_PROJECTS_DATA_LANDSCAPE,
  MARKETPLACE_PROJECTS_ENVIRONMENT,
  MARKETPLACE_PROJECTS_URN,
} from '@agilelab/plugin-wb-search-common';
import { ErrorPanel } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { ViewTypeSelector } from '../components/ViewTypeSelector';
import { useSearchContext } from '../context/SearchContext';
import { MarketplaceSearchListItem } from '../SearchResult/MarketplaceSearchListItem';
import { SearchResultsLoader } from './loaders/SearchResultsLoader';
import { SearchResultsTableLoader } from './loaders/SearchResultsTableLoader';
import { SearchResultsTable } from './SearchResultsTable';
import { useNavigate } from 'react-router';
import { useTaxonomySelection } from '@agilelab/plugin-wb-practice-shaper';
import { parseEntityRef } from '@backstage/catalog-model';

type SearchResultsContentProps = {
  noDataComponent?: ReactNode;
};

const DEFAULT_NO_DATA = (
  <div style={{ flex: 1 }}>
    <WbNoData fillContainer text="No results found" />
  </div>
);

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      gap: theme.spacing(1),
    },
    resultContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
      height: '100%',
      overflow: 'hidden',
    },
    divider: {
      marginBlock: theme.spacing(1.5),
    },
    resultsHeader: {
      paddingRight: theme.spacing(1),
      paddingBlock: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    resultsHeaderText: {
      color: theme.palette.secondary.main,
      '& > b': {
        fontWeight: 700,
      },
      fontWeight: 400,
    },
  }),
  { name: 'SearchResultsContent' },
);

export const SearchResultsContent = ({
  noDataComponent = DEFAULT_NO_DATA,
}: SearchResultsContentProps) => {
  const classes = useStyles();

  const { environment } = useSelectorsContext();

  const { selectedTaxonomyRef } = useTaxonomySelection();

  const resultsRef = useRef<HTMLDivElement>(null);
  const { clientHeight: resultsHeight } = useElementSize(resultsRef);

  const configApi = useApi(configApiRef);

  const isConsumableEnabled = configApi.getOptionalBoolean(
    'mesh.marketplace.ui.searchPage.showConsumableFilterSwitch',
  );

  const prototypePageEnabled = configApi.getOptionalBoolean(
    'mesh.builder.ui.prototypePage.enabled',
  );

  // only show the link to prototype creation if the taxonomy is data-mesh (hardcoded for prototype demo)
  const showNewPrototypeLink = useMemo(() => {
    let show = true;
    try {
      show =
        !!prototypePageEnabled &&
        parseEntityRef(selectedTaxonomyRef).name.includes('data-mesh');
    } catch {
      show = false;
    }

    return show;
  }, [prototypePageEnabled, selectedTaxonomyRef]);

  const navigate = useNavigate();

  const {
    view,
    value,
    loading,
    error,
    limit,
    cursor,
    setLimit,
    setCursor,
    query,
    setQuery,
    filters,
    setFilters,
    setFavoriteFilter,
    favoriteFilter,
    filtersContext,
    filterStateValid,
  } = useSearchContext();

  const handleChangePage = (newPage: number) => {
    setCursor(encodeBase64PageCursor(newPage));
  };

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
  };

  const countlessOptions = useMemo<CountlessOptions>(
    () => ({
      hasNextPage: () => !!value?.nextPageCursor,
      hasPrevPage: () => !!value?.previousPageCursor,
    }),
    [value],
  );

  const renderResults = () => {
    if (
      loading ||
      filtersContext.availableValues.loading ||
      // If the filter state is currenlt not valid, it means it's being updated to a valid state so instead of showing its results we show the Loader until it's valid again
      filterStateValid === false
    )
      return view === 'table' ? (
        <SearchResultsTableLoader />
      ) : (
        <SearchResultsLoader />
      );
    if (error) return <ErrorPanel error={error} />;
    if (!value) return <ErrorPanel error={new Error('Something went wrong')} />;

    let resultsComponent = noDataComponent;

    if (value.results.length && view === 'table')
      resultsComponent = <SearchResultsTable rows={value.results} />;

    if (value.results.length && view === 'cards')
      resultsComponent = (
        <div style={{ flex: 1, overflowY: 'scroll' }}>
          <List>
            {value.results.map(r => (
              <MarketplaceSearchListItem
                key={r.document.documentId}
                searchResult={r}
              />
            ))}
          </List>
        </div>
      );

    return (
      <>
        <Box className={classes.resultsHeader}>
          {!!value.numberOfResults && (
            <Typography component="span" className={classes.resultsHeaderText}>
              <b>{value.numberOfResults} results</b> in{' '}
              <b>{camelCaseToCapitalCase(environment?.name || '')}</b>
            </Typography>
          )}
          <ViewTypeSelector />
        </Box>
        {resultsComponent}
        <WbDivider orientation="horizontal" />
        <WbPagination
          rowsPerPageOptions={[15, 25, 50]}
          count={-1}
          limit={limit}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          countlessOptions={countlessOptions}
          currentPage={decodeBase64PageCursor(cursor)}
        />
      </>
    );
  };

  const hiddenFilters = useMemo(() => {
    const hidden = [
      MARKETPLACE_PROJECTS_URN.label,
      MARKETPLACE_PROJECTS_ENVIRONMENT.label,
      MARKETPLACE_PROJECTS_DATA_LANDSCAPE.label,
    ];

    if (!isConsumableEnabled)
      hidden.push(MARKETPLACE_PROJECTS_CONSUMABLE.label);

    return hidden;
  }, [isConsumableEnabled]);

  return (
    <Box className={classes.root}>
      <WbSearch value={query} debounced onChange={setQuery} />
      <WbCard
        title="Search Results"
        cardStyle={{ flex: 1, overflow: 'hidden' }}
        actions={
          showNewPrototypeLink ? (
            <WbCardActionButton
              label="Request a Data Product"
              onClick={() => navigate('/prototypes/edit')}
              variant="outlined"
            />
          ) : undefined
        }
      >
        <WbDockableGroup direction="horizontal">
          <WbDockablePanel
            defaultSize={16}
            maxSize={24}
            minSize={16}
            style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
          >
            <SearchFilters
              context={filtersContext}
              hiddenFilterLabels={hiddenFilters}
              filters={filters ?? {}}
              setFilters={setFilters}
              modalAnchorEl={resultsRef.current}
              modalHeight={resultsHeight}
              setFavoriteFilter={setFavoriteFilter}
              favoriteFilter={favoriteFilter}
            />
          </WbDockablePanel>
          <WbDockableResizeHandle
            showIconOnHover
            divider
            direction="horizontal"
          />
          <WbDockablePanel defaultSize={84}>
            <div className={classes.resultContainer} ref={resultsRef}>
              {renderResults()}
            </div>
          </WbDockablePanel>
        </WbDockableGroup>
      </WbCard>
    </Box>
  );
};
