import React from 'react';
import { TextFieldProps } from '@material-ui/core';
import { WbTextField } from '../../../WbTextField';
import { BaseSidebarFilter } from '../types';
import { FilterGroup } from '../FilterGroup';

type TextFilterGroupProps = BaseSidebarFilter & TextFieldProps;

export const TextFilterGroup = ({ label, ...props }: TextFilterGroupProps) => {
  return (
    <FilterGroup label={label}>
      <WbTextField multiline debounceMs={300} clearable {...props} />
    </FilterGroup>
  );
};
