import React, { ReactNode } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import clsx from 'clsx';

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(3),
    },
    fillContainer: {
      height: '100%',
      width: '100%',
    },
    iconContainer: {},
    defaultIcon: {
      width: 100,
      height: 100,
      display: 'block',
    },
    actionsContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: theme.spacing(1),
    },
    textContent: {
      textAlign: 'center',
    },
  }),
  { name: 'WbNoData' },
);

type StyleClasses = { [key in keyof ReturnType<typeof useStyles>]?: string };

type ExposedClasses = Omit<StyleClasses, 'defaultIcon'>;

type WbNoDataProps = {
  text?: ReactNode;
  icon?: ReactNode;
  header?: ReactNode;
  primaryAction?: ReactNode;
  secondaryAction?: ReactNode;
  fillContainer?: boolean;
  classes?: ExposedClasses;
  classeName?: string;
  style?: React.CSSProperties;
  showIcon?: boolean;
};

export const WbNoData = ({
  header,
  text,
  icon,
  primaryAction,
  secondaryAction,
  fillContainer,
  classeName,
  style,
  showIcon = true,
  ...props
}: WbNoDataProps) => {
  const classes = useStyles();
  const textContent = text ?? 'No Data';

  return (
    <Box
      className={clsx(
        classes.root,
        fillContainer && classes.fillContainer,
        props.classes?.root,
        classeName,
      )}
      style={style}
    >
      {typeof header === 'string' ? (
        <Typography style={{ fontWeight: 700 }} variant="h6">
          {header}
        </Typography>
      ) : (
        header
      )}
      {showIcon && (
        <Box
          className={clsx(classes.iconContainer, props.classes?.iconContainer)}
        >
          {icon ?? <LayersClearIcon className={classes.defaultIcon} />}
        </Box>
      )}
      {typeof textContent === 'string' ? (
        <Typography className={classes.textContent}>{textContent}</Typography>
      ) : (
        textContent
      )}
      {(primaryAction || secondaryAction) && (
        <Box
          className={clsx(
            classes.actionsContainer,
            props.classes?.actionsContainer,
          )}
        >
          {secondaryAction}
          {primaryAction}
        </Box>
      )}
    </Box>
  );
};
