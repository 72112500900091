import {
  CollatorType,
  readCollatorFiltersConfigOptions,
} from '@agilelab/plugin-wb-search-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useMemo } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { witboostSearchApiRef } from '../api';
import { SearchFiltersContext, FilterResolversOverride } from '../types';
import { resolveAvailableValues } from '../filterResolvers';

export const useSearchFilters = (
  type: CollatorType,
  filterResolversOverride: FilterResolversOverride = {},
): SearchFiltersContext => {
  const configApi = useApi(configApiRef);
  const config = useMemo(
    () => readCollatorFiltersConfigOptions(configApi)[type],
    [configApi, type],
  );
  const searchApi = useApi(witboostSearchApiRef);

  const availableValues = useAsync(async () => {
    const res = await resolveAvailableValues(
      type,
      config,
      searchApi,
      filterResolversOverride,
    );

    return res;
  }, [config, filterResolversOverride]);

  return { config, availableValues };
};
