/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Environment } from '@agilelab/plugin-wb-platform-common';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  loadFiltersFromSessionStorage,
  saveFiltersToSessionStorage,
} from '../session';
import { useQuery } from '@apollo/client';
import { GET_ENVIRONMENTS as GET_MARKETPLACE_ENVS } from '../graphql';
import { compareEnvironments } from '@agilelab/plugin-wb-platform-common';

export interface SelectorsContextType {
  environment: Environment;
  environmentList: Environment[];
  setEnvironment: (newEnv: Environment) => void;
  setEnvironmentList: (envs: Environment[]) => void;
  disabledStatus: boolean;
  setDisabledStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultEnv = {
  id: 0,
  name: '',
};

export const SelectorsContext = React.createContext<SelectorsContextType>(
  {} as SelectorsContextType,
);

export interface SelectorsContextProviderProps {
  children?: React.ReactNode;
}

export const SelectorsContextProvider: React.FC<
  SelectorsContextProviderProps
> = ({ children }) => {
  const sessionItem = 'environment';
  const loadedEnv = loadFiltersFromSessionStorage<Environment>(sessionItem);
  const initialEnv = loadedEnv ?? defaultEnv;
  const [disabledStatus, setDisabledStatus] = useState<boolean>(false);
  const [environment, setEnv] = useState<Environment>(initialEnv);
  const [environmentList, setEnvironmentList] = useState<Environment[]>([]);
  const setEnvironment = useCallback((newEnv: Environment) => {
    saveFiltersToSessionStorage<Environment>(sessionItem, f =>
      f ? { ...f, ...newEnv } : { ...newEnv },
    );
    setEnv(newEnv);
  }, []);

  const {
    data: marketplaceData,
    loading: marketplaceLoading,
    error: marketplaceError,
  } = useQuery<{
    marketplace_environments: Environment[];
  }>(GET_MARKETPLACE_ENVS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (
      marketplaceLoading ||
      marketplaceError ||
      !marketplaceData?.marketplace_environments
    ) {
      return;
    }
    const envs =
      marketplaceData?.marketplace_environments.sort(compareEnvironments) ?? [];
    setEnvironmentList(envs);
    if (environment.name === '') {
      const priorityEnv = envs.at(0);
      if (priorityEnv) {
        setEnvironment(priorityEnv);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaceData, marketplaceLoading, marketplaceError]);

  return (
    <SelectorsContext.Provider
      value={{
        environment,
        environmentList,
        setEnvironment: (newEnv: Environment) => {
          try {
            setEnvironment(newEnv);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
          }
        },
        setEnvironmentList,
        disabledStatus,
        setDisabledStatus,
      }}
    >
      {children}
    </SelectorsContext.Provider>
  );
};

export const useSelectorsContext = () => useContext(SelectorsContext);
