import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import clsx from 'clsx';
import { BaseCheckbox } from '../BaseFilters/BaseCheckbox';
import {
  CheckboxNodeWithParent,
  CheckboxTreeItemProps,
  CheckboxTreeProps,
} from './types';
import {
  CheckboxTreeContextProvider,
  useCheckboxTreeContext,
} from './CheckboxTreeContext';
import { filter, handleCheck } from './utils';
import { useCheckboxTree } from './hooks/useCheckboxTree';

const useStyles = makeStyles(
  theme => ({
    itemChildren: {
      marginLeft: theme.spacing(3),
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
    },
    expandIcon: {
      transform: 'rotate(0deg)',
      '&$expanded': {
        transform: 'rotate(90deg)',
      },
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    childless: {
      visibility: 'hidden',
    },
    expanded: {},
    icon: {
      fontSize: '16px',
      color: theme.palette.secondary.main,
    },
  }),
  { name: 'CheckboxTree' },
);

function CheckboxTreeItem<T>({
  item,
  children,
  subtreeSelected,
  renderLabel,
}: CheckboxTreeItemProps<T>) {
  const classes = useStyles();

  const {
    expanded: expandedIds,
    selected: selectedIds,
    expandedSet,
    selectedSet,
    onExpandChange,
    onSelectionChange,
    noNesting,
    filterState,
    setFilterState,
  } = useCheckboxTreeContext();

  const expanded = filterState
    ? filterState.expandedNodes.has(item.id)
    : expandedSet.has(item.id);

  const selected = selectedSet.has(item.id);

  const onCheck = (checked: boolean) => {
    handleCheck(checked, item, selectedIds, selectedSet, onSelectionChange);
  };

  const indeterminate = !selected && subtreeSelected;

  const label = renderLabel?.(item) ?? item.label ?? item.id;

  const handleExpandToggle = () => {
    // if a filter is active, change the filter expanded state instead of the main one so we can return to it after the filter is removed
    if (filterState) {
      setFilterState(old => {
        if (!old) return old;
        const filteredExpanded = Array.from(old.expandedNodes);
        const tmp = filteredExpanded.filter(id => id !== item.id);

        return {
          ...old,
          expandedNodes: new Set(
            tmp.length === filteredExpanded.length
              ? [...filteredExpanded, item.id]
              : tmp,
          ),
        };
      });
      return;
    }

    // update the main expandend array
    const tmp = expandedIds.filter(id => id !== item.id);
    onExpandChange(
      tmp.length === expandedIds.length ? [...expandedIds, item.id] : tmp,
    );
  };

  return (
    <>
      <Box className={classes.item}>
        {!noNesting && (
          <IconButton
            aria-label={expanded ? 'collapse' : 'expand'}
            className={clsx(
              classes.expandIcon,
              expanded && classes.expanded,
              !item.children.length && classes.childless,
            )}
            size="small"
            onClick={handleExpandToggle}
          >
            <KeyboardArrowRightIcon color="primary" className={classes.icon} />
          </IconButton>
        )}
        <BaseCheckbox
          label={label}
          checked={selected}
          indeterminate={indeterminate}
          onChange={(_e, checked) => {
            onCheck(checked);
          }}
        />
      </Box>
      <Box className={classes.itemChildren}>{expanded && children}</Box>
    </>
  );
}

export function CheckboxTree<T = undefined>({
  items,
  selected,
  expanded,
  onExpandChange,
  onSelectionChange,
  renderLabel,
  filterValue,
  filterFn,
}: CheckboxTreeProps<T>) {
  const getFilterState = useCallback(() => {
    if (!filterValue) return undefined;
    return filter(filterValue, items, filterFn);
  }, [filterValue, items, filterFn]);

  const [filterState, setFilterState] = useState(getFilterState());

  useEffect(() => {
    setFilterState(getFilterState());
  }, [getFilterState]);

  const { selectedSet, expandedSet, itemsWithParent } = useCheckboxTree({
    items,
    selected,
    expanded,
    onExpandChange,
    onSelectionChange,
  });

  const renderTree: (node: CheckboxNodeWithParent<T>) => {
    element: ReactNode;
    subtreeSelected: boolean;
  } = node => {
    const hidden = filterState && !filterState.filteredNodes.has(node.id);

    if (hidden) return { element: null, subtreeSelected: false };

    const children = node.children.map(n => renderTree(n));

    const childrenSelected = children.some(c => c.subtreeSelected);

    return {
      element: (
        <CheckboxTreeItem
          key={node.id}
          renderLabel={renderLabel}
          item={node}
          subtreeSelected={childrenSelected}
        >
          {children.map(c => c.element)}
        </CheckboxTreeItem>
      ),
      subtreeSelected: childrenSelected || selectedSet.has(node.id),
    };
  };

  // true if none of the roots have children
  const noNesting = !items.some(i => !!i.children.length);

  return (
    <CheckboxTreeContextProvider
      value={{
        selected,
        expanded,
        expandedSet,
        selectedSet,
        onExpandChange,
        onSelectionChange,
        filterState,
        setFilterState,
        noNesting,
      }}
    >
      {itemsWithParent.map(i => renderTree(i).element)}
    </CheckboxTreeContextProvider>
  );
}
