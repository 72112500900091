type GetTruncateMaxLinesStyle = (maxLines?: number, wordBreak?: boolean) => any;

export const getTruncateMaxLinesStyle: GetTruncateMaxLinesStyle = (
  maxLines = 1,
  wordBreak = true,
) => ({
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': maxLines,
  overflow: 'hidden',
  display: '-webkit-box',
  wordBreak: wordBreak ? 'break-all' : 'normal',
});
