import React, { ReactNode } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { ResultTitle } from '../components/ResultTitle';

type ItemTitleProps = {
  leading?: ReactNode;
  title: string;
  location: string;
  breadcrumbs?: ReactNode;
};

const useStyles = makeStyles(
  theme => ({
    root: {
      whiteSpace: 'normal',
    },
    headerContainer: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  }),
  { name: 'ItemTitle' },
);

export const ItemTitle = ({
  leading,
  title,
  breadcrumbs,
  location,
}: ItemTitleProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.headerContainer}>
        {leading}
        <ResultTitle title={title} location={location} />
      </Box>
      {breadcrumbs}
    </Box>
  );
};
