import React, { ReactElement } from 'react';
import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import { WbCard, WbCardContent } from '../WbCard';
import { WbWidget } from '../WbWidget';
import { ActionsContextProvider, useDataPath } from '../../context';
import { CustomViewRegister } from '../CustomView/registry';
import { parseNunjucks } from '@agilelab/plugin-wb-platform-common';
import './styles.css';
import { WbNoData } from '../WbNoData';
import { getChildren } from '../CustomView';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    gap: theme.spacing(3),
    flexWrap: 'wrap',
  },
}));

CustomViewRegister.register({
  id: 'container',
  function: function ContainerView(props: Record<string, any>) {
    const theme = useTheme();
    return (
      <Grid
        container
        spacing={props.spacing || 3}
        alignItems="stretch"
        style={{
          borderLeft: props.borderLeft
            ? `1px solid ${theme.palette.grey[200]}`
            : 'none',
          borderRight: props.borderRight
            ? `1px solid ${theme.palette.grey[200]}`
            : 'none',
          borderBottom: props.borderBottom
            ? `1px solid ${theme.palette.grey[200]}`
            : 'none',
          borderTop: props.borderTop
            ? `1px solid ${theme.palette.grey[200]}`
            : 'none',
          padding: '12px 16px',
        }}
      >
        {props.children}
      </Grid>
    );
  },
});

CustomViewRegister.register({
  id: 'grid_item',
  function: function ItemView(props: Record<string, any>) {
    return (
      <Grid item xs={props.size || 12}>
        {props.children}
      </Grid>
    );
  },
});

CustomViewRegister.register({
  id: 'box',
  function: function BoxView(props: Record<string, any>) {
    return <Box>{props.children}</Box>;
  },
});

CustomViewRegister.register({
  id: 'group',
  function: function BoxView(props: Record<string, any>) {
    const theme = useTheme();
    return (
      <div
        style={{
          borderLeft: `1px solid ${theme.palette.grey[200]}`,
          paddingLeft: `${theme.spacing(2)}px`,
          gap: `${theme.spacing(2)}px`,
          width: '100%',
          height: '100%',
        }}
      >
        {props.children}
      </div>
    );
  },
});

CustomViewRegister.register({
  id: 'hbox',
  function: function HBoxView(props: Record<string, any>) {
    return (
      <Box display="flex">
        {getChildren(props).map((child: ReactElement, idx) => {
          const isLast = idx === getChildren(props).length - 1;
          return (
            <Box
              className="hboxChild"
              display={child.props.display}
              key={String(idx)}
              flex={child.props.size || 1}
              style={{ paddingRight: isLast ? '' : '24px' }}
            >
              {child}
            </Box>
          );
        })}
      </Box>
    );
  },
});

CustomViewRegister.register({
  id: 'horizontal',
  function: function HBoxViewSimple(props: Record<string, any>) {
    const classes = useStyles();
    return (
      <ActionsContextProvider actions={{ getMode: () => 'linear' }}>
        <Box className={classes.container}> {props.children}</Box>
      </ActionsContextProvider>
    );
  },
});

CustomViewRegister.register({
  id: 'grid_list',
  function: function HBoxView(props: Record<string, any>) {
    return (
      <Grid container spacing={props.spacing || 3}>
        {getChildren(props).map((child: ReactElement, idx: Number) => {
          return (
            <Grid
              key={String(idx)}
              item
              xs={child.props.size || 12}
              className="customViewGridItem"
            >
              {child}
            </Grid>
          );
        })}
      </Grid>
    );
  },
});

CustomViewRegister.register({
  id: 'card',
  function: function CardView(props: Record<string, any>) {
    const data = useDataPath('');
    if (props.noDataCheck) {
      if (!parseNunjucks(props.noDataCheck, data, true))
        return <WbNoData text={props.ndDataLabel} />;
    }
    return (
      <WbCard title={props.title || ''}>
        <WbCardContent>
          {getChildren(props).filter(m => m.props.type !== 'card_content')}
        </WbCardContent>
        {getChildren(props).filter(m => m.props.type === 'card_content')}
      </WbCard>
    );
  },
});

CustomViewRegister.register({
  id: 'card_content',
  function: function CardView(props: Record<string, any>) {
    return <WbCardContent>{props.children}</WbCardContent>;
  },
});

CustomViewRegister.register({
  id: 'sub_card',
  function: function SubCardView(props: Record<string, any>) {
    const data = useDataPath('');
    if (props.noDataCheck) {
      if (!parseNunjucks(props.noDataCheck, data))
        return <WbNoData text={props.ndDataLabel} />;
    }
    return (
      <WbWidget
        cardStyle={{ marginTop: 8, marginBottom: 8 }}
        title={props.title || ''}
      >
        <WbCardContent>{props.children}</WbCardContent>
      </WbWidget>
    );
  },
});

CustomViewRegister.register({
  id: 'no_data',
  function: function NoDataView(props: Record<string, any>) {
    if (!props.value) return <WbNoData text={props.label} />;
    return <>{props.children}</>;
  },
});

CustomViewRegister.register({
  id: 'vspace',
  function: function SubCardView() {
    const theme = useTheme();
    return <Box style={{ marginBottom: theme.spacing(2) }} />;
  },
});

CustomViewRegister.register({
  id: 'hline',
  function: function SubCardView() {
    const theme = useTheme();
    return (
      <Box
        style={{
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
          width: '100%',
        }}
      />
    );
  },
});
