import {
  camelToSpacedCase,
  isEmail,
  isEntityRef,
  isISODate,
  isURL,
} from '@agilelab/plugin-wb-platform-common';
import { parseEntityRef } from '@backstage/catalog-model';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import React, { ReactElement, isValidElement } from 'react';
import { useCustomPresentation } from '../../hooks';
import { buildJsx } from '../../utils';
import { CustomViewComponent } from './CustomView';

type RawGeneralInfo = {
  type: 'string' | 'date';
  label: string;
  value: string | undefined;
  href?: string;
  colSpan?: number;
};

type OptionsCustomViewInfoFromConfig = {
  showOnlyWithValues?: boolean;
  returnOnlyRawInfo?: boolean;
  defaults?: Record<string, any>;
};

export const useCustomViewInfoFromConfig = (
  path: string,
  options?: OptionsCustomViewInfoFromConfig,
): ReactElement[] => {
  const configApi = useApi(configApiRef);
  return (
    configApi.getOptional<Array<RawGeneralInfo | string>>(path) ?? []
  ).map(info => {
    let compProps: Record<string, any>;
    if (typeof info === 'string')
      compProps = { type: 'string', label: info, path: info };
    else compProps = info;
    Object.assign(compProps, options?.defaults || {});
    if (options?.showOnlyWithValues) {
      if ((compProps.path || '') !== '')
        compProps.showWhenExists = compProps.path;
      if ((compProps.value || '') !== '')
        compProps.showWhen = { value: compProps.value };
    }
    return <CustomViewComponent {...compProps} />;
  });
};

const isProfile = (value: string) =>
  isEntityRef(value) &&
  (parseEntityRef(value).kind === 'user' ||
    parseEntityRef(value).kind === 'group');

export const addHlines = (
  list: ReactElement[],
  hlineEveryNElements: number,
) => {
  if (hlineEveryNElements <= 0) return list;
  const result: ReactElement[] = [];
  let current = 0;
  list.forEach((e: ReactElement, i: number) => {
    const colSpan = Number(e.props.colSpan || '1');
    const totSpan = colSpan + current;
    if (totSpan >= hlineEveryNElements) {
      // if the current element overflows the available space:
      // add a divider, than the current will be the colSpan of the overflowed element
      if (totSpan > hlineEveryNElements) {
        result.push(<CustomViewComponent type="hline" />);
        current = colSpan;
      }
      result.push(e);
      // in the case the element did not overflow, but it is perfectly fitting all the space:
      // just push the divider and set the current to 0, as there is no new elements
      if (totSpan === hlineEveryNElements) {
        result.push(<CustomViewComponent key={i} type="hline" />);
        current = 0;
      }
    } else {
      current += colSpan;
      result.push(e);
    }
  });
  return result;
};

/**
 * Retrieves custom view information from an object.
 * @param obj - The object to extract custom view information from.
 * @param [path=''] - The path to prepend to each extracted key.
 * @param [options] - Additional options for customization.
 * @param [enableFilterProperties=true] - Whether to enable filtering properties.
 * @returns An array of JSX elements representing custom view components.
 */
export const getCustomViewInfoFromObject = (
  obj: Record<string, any>,
  path: string = '',
  options?: Record<string, any>,
  enableFilterProperties: boolean = true,
): ReactElement[] => {
  const excludeList = options?._exclude || [];
  const opts = options ?? {};
  const priority = (options?.priority || ['name', 'description'])
    .reverse()
    .map((p: string) => p.toLowerCase());
  return addHlines(
    Object.entries(obj ?? {})
      .sort(([a], [b]) => {
        const pa = priority.indexOf(a.toLowerCase());
        const pb = priority.indexOf(b.toLowerCase());
        if (pa < pb) return 1;
        if (pa > pb) return -1;
        return 0;
      })
      .filter(([label, _]) => excludeList.indexOf(label) < 0)
      .filter(
        ([label, value]) =>
          !enableFilterProperties ||
          typeof value === 'string' ||
          typeof value === 'boolean' ||
          typeof value === 'number' ||
          label in opts,
      )
      .map(([key, value]) => {
        const objectOptions = Object.assign(
          {},
          opts._default || { colSpan: '1' },
          opts[key] || {},
        );
        const extraProps: Record<string, any> = objectOptions;
        if (isURL(String(value || '')))
          extraProps.href =
            path === '' ? `{{ ${key} }}` : `{{ ${path}.${key} }}`;
        if (isEmail(String(value || '')))
          extraProps.href =
            path === '' ? `mailto:{{ ${key} }}` : `mailto:{{ ${path}.${key} }}`;
        let type = 'string';
        if (isISODate(String(value || ''))) type = 'date';
        if (isProfile(String(value || ''))) type = 'profile';
        return (
          <CustomViewComponent
            key={key}
            label={camelToSpacedCase(key)}
            path={path === '' ? key : `${path}.${key}`}
            type={objectOptions.type || type}
            {...extraProps}
          />
        );
      }),
    opts.hlineEveryNElements || 0,
  );
};

export const getChildren = (
  props: Record<string, any>,
): ReactElement<any>[] => {
  if (!props.children) return [];
  return React.Children.toArray(props.children).filter(isValidElement);
};

export const extractIncludeFromCode = (
  root: ReactElement,
  defTypeId: string = '',
  defTemplateId: string = '',
): Array<any> => {
  if (!root) return [];
  return (
    root.props.type === 'include'
      ? [
          {
            id: root.props.id,
            typeId: root.props.typeId || defTypeId || '',
            templateId: root.props.templateId || defTemplateId || '',
          },
        ]
      : []
  ).concat(
    getChildren(root.props).flatMap((e: ReactElement) =>
      extractIncludeFromCode(
        e,
        root.props.typeId || defTypeId || '',
        root.props.templateId || defTemplateId || '',
      ),
    ),
  );
};

export const idToLabelMap: { [key: string]: string } = {
  builder_system: 'System Detail Page',
  builder_component: 'Component Detail Page',
  marketplace_subcomponent_drawer: 'Subcomponent Drawer',
  marketplace_data_contract: 'Data Contract Drawer',
  marketplace_system: 'System Detail Page',
  marketplace_component: 'Component Detail Page',
  practice_shaper_content: 'Practice Shaper Detail Drawer',
  marketplace_search_system: 'System Search Result',
  marketplace_search_component: 'Component Search Result',
  builder_system_general: 'System General Information',
  builder_component_general: 'Component General Information',
  marketplace_data_contract_general: 'Data Contract General Information',
  marketplace_system_general: 'System General Information',
  marketplace_component_general: 'Component General Information',
  practice_shaper_content_general: 'Practice Shaper General Information',
};

export const useCustomActions = (id: string, included: any[]) => {
  const view = useCustomPresentation(id, '', '', included);
  const actions = Object.assign(
    {
      getCustomViewInfo: () => ({
        id,
        typeId: '',
        templateId: '',
      }),
      getInclude: (incId: string, incTypeId: string, incTemplateId: string) =>
        buildJsx(
          CustomViewComponent,
          (
            view.value?.included.find(
              (el: any) =>
                el.id === incId &&
                el.typeId === incTypeId &&
                el.templateId === incTemplateId,
            ) || {}
          ).code,
        ),
    },
    {},
  );
  return actions;
};
