import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { Link } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { get } from 'lodash';
import React from 'react';

import { NoDataPanel } from '../generic/NoDataPanel';
import { Descriptor } from '@agilelab/plugin-wb-marketplace-common';

const useStyles = makeStyles((theme: Theme) => {
  const border = `1px solid ${theme.palette.grey[300]}`;
  return {
    table: {
      minWidth: '800px',
      '& th': {
        borderBottom: border,
        borderTop: border,
        borderLeft: border,
        '&:last-child': {
          borderRight: border,
        },
      },
      '& td': {
        borderLeft: border,
        '&:last-child': {
          borderRight: border,
        },
      },
      '& tr:last-child': {
        '& td': {
          borderBottom: border,
        },
      },
    },
  };
});

export const DataPreviewCard = (props: {
  descriptor: Descriptor;
  title?: string;
}) => {
  const { descriptor } = props;
  const classes = useStyles();
  const theme = useTheme();
  const configApi = useApi(configApiRef);

  const liveDataConfig =
    configApi.has('mesh.marketplace.ui.outputPortPage.links.liveData') &&
    configApi.getString('mesh.marketplace.ui.outputPortPage.links.liveData');

  const liveDataLink = liveDataConfig && get(descriptor, liveDataConfig);
  const title = props.title ?? 'Data Preview';

  return (
    <WbCard
      title={title}
      toolsStyle={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '8px 16px',
        wordBreak: 'break-word',
      }}
      toolsComponents={
        liveDataLink && (
          <Link
            to={liveDataLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: theme.typography.body1.fontSize,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <OpenInNewIcon style={{ marginRight: theme.spacing(1) }} />
            Live Data
          </Link>
        )
      }
    >
      {!!descriptor?.sampleData &&
      Array.isArray(descriptor?.sampleData?.columns) &&
      Array.isArray(descriptor?.sampleData?.rows) &&
      (descriptor?.sampleData?.columns?.length || 0) > 0 ? (
        <WbCardContent>
          <TableContainer>
            <Table
              aria-label="data preview table"
              size="small"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  {descriptor?.sampleData?.columns.map(h => (
                    <TableCell key={h}>
                      <Typography
                        variant="body1"
                        style={{
                          color: theme.palette.primary.main,
                          textTransform: 'uppercase',
                        }}
                      >
                        {h}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {descriptor?.sampleData?.rows
                  .filter(r => Array.isArray(r))
                  .map((r, i) => (
                    <TableRow key={i}>
                      {r.map((v, k) => (
                        <TableCell key={k}>
                          <Typography>
                            {Array.isArray(v)
                              ? JSON.stringify(v)
                                  .replaceAll('"', '')
                                  .replaceAll(',', ', ')
                              : v}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </WbCardContent>
      ) : (
        <NoDataPanel title={title} />
      )}
    </WbCard>
  );
};
