import { FormControl } from '@material-ui/core';
import React, { useCallback } from 'react';
import { WbTextField } from '../WbTextField';
import { FieldActions } from './FieldActions';
import { useGeneralPurposePickerContext } from './GeneralPurposePickerContext';
import { KindEnum, TextFieldValue } from './types';

export const SingleTextField = ({
  title,
  freeSolo,
  required,
  value,
}: {
  title: string;
  freeSolo: boolean;
  required: boolean;
  value?: TextFieldValue;
}) => {
  const { setSelectedValues, setOpenDialog } = useGeneralPurposePickerContext();

  const onClear = useCallback(() => {
    setSelectedValues({ tableValues: [], arbitraryValues: [] });
  }, [setSelectedValues]);

  const adaptValue = (option: TextFieldValue) => {
    if (option.kind === KindEnum.arbitrary) return option.label;
    const labelKind = option?.kindDisplayName ?? option?.kind;

    return `${labelKind ? `${labelKind}:` : ''}  ${
      value?.label ? value?.label : ''
    }`;
  };

  return (
    <FormControl required={required}>
      <WbTextField
        label={title}
        required={required}
        value={value ? adaptValue(value) : undefined}
        onChange={e => {
          const targetValue = e.target.value;
          const arbitraryValue =
            targetValue !== ''
              ? [
                  {
                    label: targetValue,
                    value: targetValue,
                    kind: KindEnum.arbitrary,
                  },
                ]
              : undefined;
          setSelectedValues({
            tableValues: [],
            arbitraryValues: arbitraryValue,
          });
        }}
        onClick={() => {
          if (!freeSolo) {
            setOpenDialog(true);
          }
        }}
        InputProps={{
          readOnly: !freeSolo,
          endAdornment: (
            <FieldActions
              onClear={onClear}
              onOpen={() => setOpenDialog(true)}
            />
          ),
        }}
      />
    </FormControl>
  );
};
