import {
  BooleanFilterGroupLoader,
  CheckboxFilterGroupLoader,
  WbCardContent,
  WbDivider,
} from '@agilelab/plugin-wb-platform';
import {
  SearchFilterConfig,
  SearchFilterConfigType,
} from '@agilelab/plugin-wb-search-common';
import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { ReactNode } from 'react';
import { SearchFiltersContext } from '../../types';

const useStyles = makeStyles(
  theme => ({
    flexSpaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    divider: {
      marginBlock: theme.spacing(1.5),
    },
  }),
  { name: 'SearchFiltersLoader' },
);

const DEFAULT_LOADER = (
  <>
    <Skeleton variant="text" width={60} />
    <Skeleton variant="rect" height={45} />
  </>
);

export const SearchFiltersLoader = ({
  context,
}: {
  context: SearchFiltersContext;
}) => {
  const classes = useStyles();

  const renderFilter = (filter: SearchFilterConfig): ReactNode => {
    switch (filter.type) {
      case SearchFilterConfigType.CHOICE:
        return <CheckboxFilterGroupLoader />;

      case SearchFilterConfigType.BOOLEAN:
        return <BooleanFilterGroupLoader />;
      default:
        return DEFAULT_LOADER;
    }
  };

  return (
    <WbCardContent className={classes.root}>
      <Box className={classes.flexSpaceBetween}>
        <Skeleton variant="text" width={50} />
        <Skeleton variant="text" width={50} />
      </Box>
      {context.config.map((f, i) => (
        <React.Fragment key={f.label}>
          {renderFilter(f)}
          {i < context.config.length - 1 && (
            <WbDivider orientation="horizontal" className={classes.divider} />
          )}
        </React.Fragment>
      ))}
    </WbCardContent>
  );
};
