import { gql } from '@apollo/client/core';

export const GET_MARKETPLACE_PROJECT_COMPONENTS_IDS = gql`
  query GetComponentsBySystemId($id: String, $environment: String) {
    instances: marketplace_instances(
      where: {
        _and: [
          { kind: { _eq: "component" } }
          { environment: { name: { _eq: $environment } } }
          {
            _and: [
              {
                relations: {
                  instanceByTargetInstanceId: { external_id: { _eq: $id } }
                }
              }
              { relations: { name: { _eq: "partOfSystem" } } }
            ]
          }
        ]
      }
    ) {
      id
      external_id
    }
  }
`;

export const SEARCH_MARKETPLACE_SYSTEMS = gql`
  query SEARCH_MARKETPLACE_SYSTEMS(
    $limit: Int
    $offset: Int
    $where: marketplace_instances_bool_exp
  ) {
    marketplace_instances(limit: $limit, offset: $offset, where: $where) {
      version
      descriptor
      id
      display_name
      description
      published_at
      consumable
      external_id
      kind
      owner
      environment {
        name
      }
      taxonomy {
        name
        external_id
      }
      domains: relations(where: { name: { _eq: "partOfDomain" } }) {
        domain: instanceByTargetInstanceId {
          name
          external_id
        }
      }
    }
  }
`;

export const SEARCH_MARKETPLACE_COMPONENTS = gql`
  query SEARCH_MARKETPLACE_COMPONENTS(
    $limit: Int
    $offset: Int
    $where: marketplace_instances_bool_exp
  ) {
    marketplace_instances(limit: $limit, offset: $offset, where: $where) {
      descriptor
      id
      external_id
      display_name
      description
      consumable
      published_at
      kind
      environment {
        name
      }
      parent: relations(where: { name: { _eq: "partOfSystem" } }) {
        instanceByTargetInstanceId {
          name
          id
          owner
          display_name
          taxonomy {
            name
            external_id
          }
          domains: relations(where: { name: { _eq: "partOfDomain" } }) {
            domain: instanceByTargetInstanceId {
              name
              external_id
            }
          }
        }
      }
    }
  }
`;

export const DEPLOYED_COMPONENTS = gql`
  query DEPLOYED_COMPONENTS(
    $limit: Int
    $offset: Int
    $where: coordinator_component_provisioning_status_bool_exp
  ) {
    coordinator_component_provisioning_status(
      limit: $limit
      offset: $offset
      where: $where
    ) {
      id
      status
      component_id
      source: provisioningOperationBySourceProvisioningOperationId {
        environment
        descriptor
        descriptor_yaml
      }
    }
  }
`;

interface Taxonomy {
  name: string;
  external_id: string;
}

interface Domain {
  name: string;
  external_id: string;
}

export interface MarketplaceSystemsQueryType {
  version: string;
  descriptor: any;
  id: string;
  external_id: string;
  display_name: string;
  description: string;
  published_at: string;
  consumable: string;
  kind: string;
  owner: string;
  environment: { name: string };
  taxonomy: Taxonomy;
  domains: { domain: Domain }[];
}

export interface MarketplaceComponentsQueryType {
  descriptor: any;
  id: string;
  external_id: string;
  display_name: string;
  description: string;
  consumable: string;
  kind: string;
  environment: { name: string };
  published_at: string;
  parent: {
    instanceByTargetInstanceId: {
      name: string;
      id: string;
      owner: string;
      display_name: string;
      taxonomy: Taxonomy;
      domains: { domain: Domain }[];
    };
  }[];
}

export type ComponentDescriptor = Record<string, any> & {
  id: string;
  kind: string;
  consumable?: boolean;
  components?: ComponentDescriptor[];
};

export type SystemDescriptor = Record<string, any> & {
  id: string;
  name: string;
  domainId: string;
  domain: string;
  components?: ComponentDescriptor[];
};

export type DeployedComponentsQueryType = {
  coordinator_component_provisioning_status: {
    id: string;
    status: string;
    component_id: string;
    source: {
      environment: string;
      descriptor?: SystemDescriptor;
      descriptor_yaml?: string;
    };
  }[];
};

export type MarketplaceProjectComponentIdQueryType = {
  instances: {
    id: string;
    external_id: string;
  }[];
};
