import { PolicyByIdAndEnvsQuery } from '@agilelab/plugin-wb-governance-common';
import { Tooltip, Typography } from '@material-ui/core';
import React, { ReactNode, useCallback } from 'react';
import {
  customAlertApiRef,
  GenericList,
  GenericListItem,
  TestStatus,
} from '@agilelab/plugin-wb-platform';
import { DagStatus } from '@agilelab/plugin-wb-builder-common';
import {
  ComponentInstanceEntity,
  InstanceEntity,
} from '@agilelab/plugin-wb-marketplace-common';
import {
  GET_POLICY_BY_POLICY_ID_AND_ENVS,
  governanceApiRef,
} from '@agilelab/plugin-wb-governance';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import { useApolloClient } from '@apollo/client';
import useAsync from 'react-use/lib/useAsync';

const statusIconStyle = {
  marginRight: '8px',
  display: 'flex',
  alignItems: 'center',
};

export function PolicyViolationsOverview({
  title,
  hideIfEmpty = false,
  noDataMessage,
  policyIdFilter,
  system,
  components,
  wrapper,
}: {
  title?: string;
  hideIfEmpty?: boolean;
  noDataMessage?: string;
  policyIdFilter: string[];
  system: InstanceEntity;
  components: ComponentInstanceEntity[];
  wrapper?: (children: ReactNode) => JSX.Element;
}): JSX.Element {
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);
  const governanceApi = useApi(governanceApiRef);
  const apolloClient = useApolloClient();

  const fetchPolicy = useCallback(
    async (policyId: string) => {
      const { data } = await apolloClient.query<PolicyByIdAndEnvsQuery>({
        query: GET_POLICY_BY_POLICY_ID_AND_ENVS,
        variables: { policyId },
      });
      return data.cgp_governance_entity[0];
    },
    [apolloClient],
  );

  const resolveComponentName = (componentId: string) => {
    const component = components.find(c => c.external_id === componentId);
    if (!component) return undefined;
    return component.display_name ?? component.name;
  };

  const {
    loading,
    value: violationItems,
    error,
  } = useAsync(async () => {
    if (!policyIdFilter.length) return [];
    const { violations } = await governanceApi.getPolicyViolations(
      {
        resource: system.external_id,
        environment: system.environment.name,
        policies: policyIdFilter,
      },
      await identityApi.getCredentials(),
    );

    return await Promise.all(
      violations.map(async violation => {
        const policy = await fetchPolicy(violation.policyId);
        const variant =
          violation.resourceVariantId &&
          (resolveComponentName(violation.resourceVariantId) ??
            'internal component');
        return {
          policyName: policy.name,
          policyVersion: `v:${policy.version}`,
          policyDescription: policy.description,
          outcome: violation.outcome.toUpperCase() as DagStatus,
          variant: variant,
        };
      }),
    );
  }, [governanceApi, identityApi, alertApi, fetchPolicy]);

  const items: GenericListItem[] | undefined = violationItems?.map(v => ({
    icon: (
      <TestStatus
        style={statusIconStyle}
        status={v.outcome}
        filled
        variant="reduced"
      />
    ),
    text: (
      <Tooltip title={v.policyDescription ?? v.policyName}>
        <Typography>{`${v.policyName} ${v.policyVersion}`}</Typography>
      </Tooltip>
    ),
    additionalInfo: v.variant && `Computed on ${v.variant}`,
  }));

  if (hideIfEmpty && (loading || (items && !items.length))) return <></>;

  const list = (
    <GenericList
      label={title}
      isLoading={loading}
      isError={!!error}
      error={
        error && (
          <Typography>Policy violations are temporarily unavailable</Typography>
        )
      }
      items={items}
      noData={<Typography>{noDataMessage ?? 'No violations'}</Typography>}
    />
  );

  return wrapper ? wrapper(list) : list;
}
