import {
  CheckboxNode,
  CheckboxNodeWithParent,
  FilterFn,
  FilterState,
} from './types';

export function getSubtreeSet<T>(root: CheckboxNode<T>) {
  const res = new Set<string>();

  const traverse = (node: CheckboxNode<T>) => {
    res.add(node.id);
    node.children.forEach(c => {
      traverse(c);
    });
  };

  traverse(root);

  return res;
}

// builds a new array of roots in which every node has a reference to its parent as well
export function buildItemsWithParents<T>(items: CheckboxNode<T>[]) {
  const clone: CheckboxNodeWithParent<T>[] = structuredClone(items);

  function traverse(node: CheckboxNodeWithParent<T>) {
    node.children.forEach(c => {
      c.parent = node;
      traverse(c);
    });
  }

  clone.forEach(i => traverse(i));

  return clone;
}

// given a node with parent, expand all its ancestors to show the node
export function expandToItem<T>(
  item: CheckboxNodeWithParent<T>,
  onExpandToggle: (expandedIds: string[]) => void,
) {
  const expandedIds: string[] = [];

  let parent = item.parent;

  while (parent) {
    expandedIds.push(parent.id);
    parent = parent.parent;
  }

  onExpandToggle(expandedIds);
}

// returns the nodes to show for a flat selection preview, only the first node from each branch will be returned
export function getFlatSelectionPreview<T>(
  items: CheckboxNodeWithParent<T>[],
  selected: Set<string>,
  handleCheckInner: (checked: boolean, item: CheckboxNodeWithParent<T>) => void,
  onExpandToggle: (expandedIds: string[]) => void,
) {
  const res: (CheckboxNodeWithParent<T> & {
    uncheck: () => void;
    expandTo: () => void;
  })[] = [];

  function traverse(node: CheckboxNodeWithParent<T>) {
    if (selected.has(node.id)) {
      res.push({
        ...node,
        uncheck: () => handleCheckInner(false, node),
        expandTo: () => expandToItem(node, onExpandToggle),
      });
      return;
    }

    node.children.forEach(c => {
      traverse(c);
    });
  }

  items.forEach(i => traverse(i));

  return res;
}

export function handleCheck<T>(
  checked: boolean,
  item: CheckboxNodeWithParent<T>,
  selectedIds: string[],
  selectedSet: Set<string>,
  onItemCheck: (checkedIds: string[]) => void,
) {
  // all the subtree will be updated
  const diff = getSubtreeSet(item);
  let parent = item.parent;

  let allSelected = true;

  // update all the ancestors based on the new check
  while (parent) {
    if (!checked) {
      diff.add(parent.id);
      parent = parent.parent;
      continue;
    }
    if (allSelected) {
      allSelected = parent.children.every(
        c => diff.has(c.id) || selectedSet.has(c.id),
      );
    }

    if (allSelected) diff.add(parent.id);
    parent = parent.parent;
  }
  // if checked, add the nodes in diff to selection
  if (checked)
    onItemCheck(Array.from(new Set([...selectedIds, ...Array.from(diff)])));
  // if not checked, the elements in diff will be deselected
  else onItemCheck(selectedIds.filter(id => !diff.has(id)));
}

function defaultFilterFn<T>(search: string, node: CheckboxNode<T>): boolean {
  return new RegExp(search, 'ig').test(node.label ?? node.id);
}

export function filter<T>(
  search: string,
  items: CheckboxNode<T>[],
  filterFn: FilterFn<T> = defaultFilterFn,
): FilterState {
  const filteredNodes = new Set<string>();

  const expandedNodes = new Set<string>();

  function traverse(node: CheckboxNode<T>, ancestorMatch?: boolean): boolean {
    const match = filterFn(search, node);

    let childrenMatch = false;

    node.children.forEach(child => {
      const childMatch = traverse(child, match || ancestorMatch);
      childrenMatch = childrenMatch || childMatch;
    });

    // if the node has children that match in its subtree we need to expand it
    if (childrenMatch) expandedNodes.add(node.id);
    // if an anchestor matches, the node itself matches or a node from its subtree matches, we will show the node
    if (ancestorMatch || match || childrenMatch) filteredNodes.add(node.id);
    return match || childrenMatch;
  }

  items.forEach(c => traverse(c));

  return { filteredNodes, expandedNodes };
}
