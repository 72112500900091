import React, { useEffect } from 'react';
import { useSelectorsContext } from '../../context/SelectorsContext';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { Typography } from '@material-ui/core';
import { WbSelectInline, WbSelectInlineItem } from '../WbSelectInline';

interface Props {
  disabled?: boolean;
  defaultValue?: string;
  onChange?: (e: string) => void;
}

export const WbEnvironmentSelector: React.FC<Props> = ({
  disabled,
  onChange,
}) => {
  const {
    environmentList,
    environment,
    setEnvironment,
    disabledStatus,
    setDisabledStatus,
  } = useSelectorsContext();
  useEffect(
    () => setDisabledStatus(disabled ?? false),
    [disabled, setDisabledStatus],
  );

  if (!environmentList.length) {
    return <></>;
  }

  return (
    <WbSelectInline
      title="Environment:"
      menuTitle="Environments"
      value={environment.name}
      disabled={disabledStatus || environmentList.length === 1}
      onChange={e => {
        setEnvironment(environmentList.find(env => env.name === e)!);

        if (onChange) onChange(e);
      }}
    >
      {environmentList.map(e => (
        <WbSelectInlineItem {...{ key: e.name, value: e.name }}>
          <Typography variant="body2">
            {snakeCaseToTitleCase(e.name)}
          </Typography>
        </WbSelectInlineItem>
      ))}
    </WbSelectInline>
  );
};
