import { SearchFilterVisitor } from './visitors/SearchFilterVisitor';

export enum TextFilterMatch {
  EXACT = 'exact',
  BEGINS = 'begins',
  ENDS = 'ends',
  CONTAINS = 'contains',
}

export enum FilterOperator {
  EQ = 'eq',
  NE = 'ne',
  LE = 'le',
  GE = 'ge',
  IN = 'in',
  NIN = 'nin',
  LIKE = 'like',
}

export type FilterValue = string;

export type AtomicFilterInputValue = FilterValue | boolean | number | Date;

export enum QueryOperator {
  AND = 'AND',
  OR = 'OR',
}

export interface Filter {
  and(...filter: Filter[]): Filter;

  or(...filter: Filter[]): Filter;

  accept(visitor: SearchFilterVisitor): any;
}

export class ComplexFilter implements Filter {
  constructor(public operator: QueryOperator, public filters: Filter[]) {}

  and(...filter: Filter[]): Filter {
    return new ComplexFilter(QueryOperator.AND, [this, ...filter]);
  }

  or(...filter: Filter[]): Filter {
    return new ComplexFilter(QueryOperator.OR, [this, ...filter]);
  }

  accept(visitor: SearchFilterVisitor): any {
    return visitor.visitComplexFilter(this);
  }
}

export class AtomicFilter implements Filter {
  public value: FilterValue | FilterValue[];

  constructor(
    public field: string,
    public operator: FilterOperator,
    inputValue: AtomicFilterInputValue | AtomicFilterInputValue[],
  ) {
    this.value = Array.isArray(inputValue)
      ? inputValue.map(v => this.parseInputValue(v))
      : this.parseInputValue(inputValue);
  }

  private parseInputValue(inputValue: AtomicFilterInputValue): FilterValue {
    if (inputValue instanceof Date) {
      return inputValue.toISOString();
    }
    return inputValue.toString();
  }

  and(...filter: Filter[]): Filter {
    return new ComplexFilter(QueryOperator.AND, [this, ...filter]);
  }

  or(...filter: Filter[]): Filter {
    return new ComplexFilter(QueryOperator.OR, [this, ...filter]);
  }

  accept(visitor: SearchFilterVisitor): any {
    return visitor.visitAtomicFilter(this);
  }
}

export type AvailableSearchFilterValues = {
  [label: string]: { value: string; displayValue?: string }[];
};

export enum SearchFilterConfigType {
  CHOICE = 'choice',
  TEXT = 'text',
  BOOLEAN = 'boolean',
  DATE = 'date',
  CONSUMABLE = 'consumable',
  DOMAIN = 'domain',
  TYPE = 'type',
  FAVORITES = 'favorites',
}

export function isValidSearchFilterType(value: string): boolean {
  return Object.values(SearchFilterConfigType).includes(
    value as SearchFilterConfigType,
  );
}
